import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import CategoryAddEditModal from "./CategoryAddEditModal";
import {Toast} from "../../utils/toast";
import {CategoryObject} from "../../models/Category";
import {sortBy} from "lodash";
import {CategoryEditButton} from "./CategoryEditButton";
import {CategoryFilters} from "../../utils/constants";
import {getCategoryLabel} from "../../utils/helpers";

export default function CategoryPage() {
    const [categoryList, setCategoryList] = useState([]);
    const [isShowAddCat, setIsShowAddCat] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiServices.category.getCategoryList()
            .then(res => setCategoryList(sortBy(res, "sortKey")))
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [])

    const toggleAddCategory = () => {
        setIsShowAddCat(!isShowAddCat);
    }

    const onAddCategory = (payload) => {
        setIsLoading(true);
        return ApiServices.category.addCategory(payload)
            .then(res => {
                setCategoryList(sortBy([
                    new CategoryObject({...payload, id: res.id}),
                    ...categoryList
                ], "sortKey"));
                Toast.showSuccess('Tạo danh mục thành công.');
                toggleAddCategory();
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    const onEditCategory = (payload) => {
        setIsLoading(true);
        return ApiServices.category.editCategory(payload)
            .then(() => {
                setCategoryList(sortBy([
                    new CategoryObject(payload),
                    ...categoryList.filter(cat => cat.id !== payload.id)
                ], "sortKey"));
                Toast.showSuccess('Sửa danh mục thành công.');
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    /**@param {CategoryObject} category*/
    const onClickDeleteItem = (category) => {
        ApiServices.category.deleteCategory(category.slug)
            .then(() => Toast.showSuccess("Xóa danh mục thành công."))
            .then(() => setCategoryList(categoryList.filter(cat => cat.id !== category.id)))
            .catch(() => Toast.showError());
    }

    const columns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Thứ tự',
            dataIndex: 'sortKey',
            key: 'sortKey',
        },
        {
            title: 'Giới hạn',
            key: 'limit',
            render: (text, record) => {
                if (!record.limit.length) return "Tất cả";
                if (record.limit.length === Object.keys(CategoryFilters).length) return "Tất cả";
                return record.limit.map(k => getCategoryLabel(k)).join(", ");
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <CategoryEditButton record={record} onSave={onEditCategory}/>
                    <Popconfirm
                        title="Xóa danh mục sẽ xóa tất cả bài học trong danh mục đó. Xóa?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Danh mục"/>
            <div>
                <div className="page__title">
                    <h1>Danh mục</h1>
                    <Button
                        type="text"
                        className="button--add"
                        onClick={toggleAddCategory}
                    >
                        Thêm danh mục
                    </Button>
                </div>
                <Table dataSource={categoryList} columns={columns}/>
            </div>
            <Modal visible={isShowAddCat} footer={null} onCancel={toggleAddCategory} width={1000} destroyOnClose>
                <CategoryAddEditModal onSave={onAddCategory} isLoading={isLoading}/>
            </Modal>
        </BasePage>
    )
}
