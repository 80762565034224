/**@property {String} id
 * @property {String} key
 * @property {String} name
 * @property {String} slug
 * @property {String} categoryId
 * @property {String} description
 * @property {Number} duration
 * @property {String} teacher
 * @property {String} profileImage
 * @property {String} profileImageUrl
 * @property {Array<LessonObject>} details*/
export class ClassObject {
    constructor(data) {
        this.id = data.id;
        this.key = this.id;
        this.slug = data.slug;
        this.title = data.title || "";
        this.categoryId = data.categoryId || data.category_id || "";
        this.description = data.description || "";
        this.formsUrl = data.formsUrl || "";
        this.duration = data.duration || 0;
        this.teacher = data.teacher || "";
        this.profileImage = data.profileImage || data.profile_image || "";
        this.profileImageUrl = data.profile_image_url_get || data.profile_image_url || "";
        this.lessonCount = data.lessonCount || data.lesson_count || 0;
    }
}