import {BaseObject} from "./BaseObject";

export class LandingPageObject extends BaseObject {
    constructor(data) {
        super(data);
        this.htmlContent = data.html_content || 'Không có nội dung';

        this.imgDataLarge = data.imgDataLarge;
        this.largeImgUrl = data.large_img_url_get || data.largeImgUrl;

        this.imgDataSmall = data.imgDataSmall;
        this.smallImgUrl = data.small_img_url_get || data.smallImgUrl;
    }
}
