export class ExamObject {
    constructor(data) {
        this.id = data.id;
        this.key = this.id;
        this.slug = data.slug;
        this.imgUrl = data.profile_image_url || data.profile_image_url_get || data.imgUrl || "";
        this.title = data.title || "";
        this.description = data.description || "";
        this.imgName = data.imgName || "";
        this.formsUrl = data.formsUrl || data.forms_url || "";
        this.categoryId = data.categoryId || data.category_id;
    }
}