/**@property {String} id
 * @property {String} key
 * @property {String} slug
 * @property {String} title
 * @property {LessonLevel} level
 * @property {LessonType} type
 * @property {String} pdf
 * @property {String} video
 * @property {String} content
 * @property {String} pdfName*/
export class LessonObject {
    constructor(data) {
        this.id = data.id;
        this.key = this.id;
        this.slug = data.slug;
        this.classId = data.classId || data.class_id;
        this.categoryId = data.categoryId || data.category_id || '';
        this.title = data.title || "";
        this.level = data.level;
        this.type = data.type;
        this.pdf = data.pdf || "";
        this.video = data.video || "";
        this.text = data.text || "";
        this.pdfName = data.pdfName;
    }
}