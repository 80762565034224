import {ApiBase} from "./ApiBase";
import {LandingPageObject} from "../../models/LandingPageObject";

export class ApiLanding extends ApiBase {
    /**@return {Promise<Array<LandingPageObject>>}*/
    getLandingPages() {
        return this._requester.get('/landing_page/list')
            .then(res => res.data.data)
            .catch(() => [])
            .then(res => res.map(item => new LandingPageObject(item)));
    }

    editLandingPage(payload) {
        return this._requester.put('/landing_page', this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    addLandingPage(payload) {
        return this._requester.post('/landing_page', this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    deleteLandingPage(slug) {
        return this._requester.delete('/landing_page/' + slug);
    }
}
