export class UserCredentials {
    constructor(data) {
        this.apiKey = data.apiKey || "";
        this.email = data.email || "";
        this.refreshToken = data.stsTokenManager.refreshToken || "";
        this.accessToken = data.stsTokenManager.accessToken || "";
        this.expirationTime = data.stsTokenManager.expirationTime || 0;
        this.userId = data.uid || "";
        this.displayName = data.displayName || "";
    }
}