import {nanoid} from "nanoid";
import moment from "moment";

export class EventObject {
    constructor(data) {
        this.id = data.id || nanoid();
        this.key = this.id;
        this.slug = data.slug;
        this.imgUrl = data.profile_image_url || data.profile_image_url_get || data.imgUrl || "";
        this.startDate = moment(data.start) || data.startDate;
        this.endDate = moment(data.end) || data.endDate;
        this.title = data.title || "";
        this.description = data.description || "";
        this.content = data.content || "";
        this.url = data.url || "";
        this.imgName = data.imgName || "";
        this.imgData = data.imgData;
    }
}