export class JobObject {
    constructor(data) {
        this.id = data.id || "";
        this.key = this.id;
        this.slug = data.slug;
        this.title = data.title || "";
        this.description = data.description || "";
        this.location = data.location || "";
        this.position = data.position || "";
        this.url = data.url || "";
        this.isOpen = data.isOpen || data.is_open || false;
        this.imgName = data.imgName || "";
        this.imgUrl = data.imgUrl || data.profile_image_url || data.profile_image_url_get || "";
    }
}