import React from 'react';
import styles from "./NotFound.module.css";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {PathNames} from "../../../utils/pathNames";
import PageTitle from "../PageTitle";

export default function NotFound() {
    return (
        <div className={styles.wrapper}>
            <PageTitle pageName="404 Không tồn tại"/>
            <h1>Đường dẫn không tồn tại.</h1>
            <Button type="default">
                <Link to={PathNames.HomePage}>
                    Quay về trang chủ
                </Link>
            </Button>
        </div>
    )
}