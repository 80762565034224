import "firebase/auth";
import {FirebaseBase} from "./FirebaseBase";
import {UserCredentials} from "../../models/UserCredentials";

export class AuthService extends FirebaseBase {
    /**@param {String} email
     * @param {String} password
     * @return {Promise<UserCredentials>}*/
    login(email, password) {
        return this._firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                return new UserCredentials(userCredential.user.toJSON());
            });
    }

    /**@return {Promise<void>}*/
    logout() {
        return this._firebase.auth().signOut();
    }

    /**@return {Promise<String>}*/
    getAccessToken() {
        return this._firebase.auth().currentUser?.getIdToken(true);
    }
}