import {ApiBase, HttpMethods} from "./ApiBase";
import {ExamObject} from "../../models/ExamObject";

export default class ApiExam extends ApiBase {
    /**@return {Promise<ExamObject[]>}*/
    getExamList() {
        return this._generateRequest("/exam/list")
            .then(fetch)
            .then(this._handleResponse)
            .then(res => res.data.map(ex => new ExamObject(ex)))
            .catch(console.error);
    }

    /**@param {ExamObject} payload*/
    addExam(payload) {
        return this._requester.post("/exam", this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    /**@param {ExamObject} payload*/
    editExam(payload) {
        return this._requester.put("/exam", this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    /**@param {String} slug*/
    deleteExam(slug) {
        return this._generateRequest("/exam/" + slug, HttpMethods.DELETE)
            .then(fetch)
            .then(this._handleNonJSONResponse);
    }
}
