export class CategoryObject {
    constructor(data) {
        this.id = data.id || "";
        this.key = this.id;
        this.slug = data.slug;
        this.title = data.title || "";
        this.limit = data.limit || [];
        this.sortKey = data.sortKey || data.sort_key || 0;
        this.description = data.description || {};
    }
}