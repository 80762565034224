import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import React, {useEffect, useState} from "react";
import {Button, Modal, Popconfirm, Select, Space, Table} from "antd";
import ApiServices from "../../services/apiservices";
import style from "./LessonPage.module.css";
import LessonAddEditModal from "./LessonAddEditModal";
import {truncate} from "lodash";
import {LessonEditButton} from "./LessonEditButton";
import {LessonLevel, LessonType} from "../../utils/constants";
import {Toast} from "../../utils/toast";
import {LessonObject} from "../../models/LessonObject";
import {extractFileName, stripHtml} from "../../utils/helpers";

export default function LessonPage() {
    const [selectedClass, setSelectedClass] = useState("");
    const [classList, setClassList] = useState([]);
    const [lessonList, setLessonList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        ApiServices.class.getClassList()
            .then(setClassList)
            .catch(console.error)
            .finally(() => setIsLoading(false));
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (classId) => {
        setIsLoading(true);
        setSelectedClass(classId);
        ApiServices.lesson.getLessonOfClass(classId)
            .then(setLessonList)
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }

    const toggleAddLesson = () => {
        setShowAdd(!showAdd);
    }

    const onAddLesson = (payload, pdfFile) => {
        const newLesson = new LessonObject(payload);
        return ApiServices.lesson.addLesson(payload)
            .then(res => {
                newLesson.id = res.id;
                newLesson.key = res.id;
                newLesson.slug = res.slug;
                return pdfFile ? ApiServices.lesson.uploadFile(res.pdf_url_put, pdfFile) : null;
            })
            .then(() => {
                if (newLesson.classId === selectedClass) {
                    setLessonList([newLesson, ...lessonList]);
                }
                Toast.showSuccess("Tạo bài học thành công.");
                toggleAddLesson();
            })
            .catch(() => Toast.showError);
    }

    const onEditLesson = (payload, pdfFile) => {
        return ApiServices.lesson.editLesson(payload)
            .then(res => {
                pdfFile && ApiServices.news.uploadFile(res.pdf_url_put, pdfFile);
                Toast.showSuccess("Sửa bài học thành công.");
                if (payload.classId !== selectedClass) {
                    setLessonList(lessonList.filter(ls => ls.id !== payload.id));
                } else {
                    setLessonList(lessonList.map(ls => {
                        return ls.id === payload.id ? payload : ls;
                    }))
                }

            })
            .catch(() => Toast.showError())
    }

    const onDeleteLesson = (lessonObject) => {
        ApiServices.lesson.deleteLesson(lessonObject.slug)
            .then(() => Toast.showSuccess("Xóa bài học thành công"))
            .then(() => setLessonList(lessonList.filter(ls => ls.id !== lessonObject.id)))
            .catch(() => Toast.showError());
    }

    const getLevelName = (level) => {
        if (level === LessonLevel.BASIC) return "Cơ bản";
        if (level === LessonLevel.INTERMEDIATE) return "Trung bình khá";
        if (level === LessonLevel.ADVANCED) return "Nâng cao";
    }

    const getLessonType = (type) => {
        if (type === LessonType.GRAMMAR) return "Ngữ pháp";
        if (type === LessonType.VOCABULARY) return "Từ vựng";
        if (type === LessonType.EXAM) return "Kiểm tra";
    }

    const detailColumns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Trình độ',
            dataIndex: 'level',
            key: 'level',
            render: (text, record) => getLevelName(record.level)
        },
        {
            title: 'Phân loại',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => getLessonType(record.type)
        },
        {
            title: "Nội dung",
            key: "text",
            render: (text, record) => (
                <div>
                    Nội dung chữ: {truncate(stripHtml(record.text))}<br/>
                    File pdf: {extractFileName(record.pdf)}<br/>
                    Video: {record.video}<br/>
                </div>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <LessonEditButton record={record} onSave={onEditLesson} classList={classList}/>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => onDeleteLesson(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Bài học"/>
            <div>
                <h1 className="page__title">
                    Bài học
                </h1>
                <Space className={style.selectWrapper}>
                    <h4>Chương trình học: </h4>
                    <Select style={{width: 200}} onChange={handleChange}>
                        {classList && classList.length && classList.map(cls => (
                            <Select.Option key={cls.id} value={cls.id}>{cls.title}</Select.Option>
                        ))}
                    </Select>
                    <Button type="text" className="button--add" onClick={toggleAddLesson}>
                        Thêm bài học
                    </Button>
                </Space>
                {!selectedClass
                    ? <p className={style.mustSelectClass}> Vui lòng chọn chương trình học để xem danh sách.</p>
                    : <Table dataSource={lessonList} columns={detailColumns}/>
                }
                <Modal visible={showAdd} footer={null} onCancel={toggleAddLesson} width={1000} destroyOnClose>
                    <LessonAddEditModal onSave={onAddLesson} classList={classList}/>
                </Modal>
            </div>
        </BasePage>
    )
}