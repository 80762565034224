import React, {Fragment, useState} from "react";
import {Button, Modal} from "antd";
import ClassAddEditModal from "./ClassAddEditModal";
import {arrayOf, func, instanceOf} from "prop-types";
import {ClassObject} from "../../models/ClassObject";
import {CategoryObject} from "../../models/Category";

/**@param {ClassObject} record
 * @param {Function} onSave
 * @param {CategoryObject[]} categoryList*/
export function ClassEditButton({record, onSave, categoryList = []}) {
    const [isEdit, setIsEdit] = useState(false);

    const toggleEditButton = () => {
        setIsEdit(!isEdit);
    }

    const saveHandler = (...params) => {
        onSave(...params).then(() => setIsEdit(false));
    }

    return (
        <Fragment>
            <Button type="primary" onClick={toggleEditButton}>Sửa</Button>
            <Modal visible={isEdit} footer={null} onCancel={toggleEditButton} width={1000} destroyOnClose>
                <ClassAddEditModal editPayload={record} onSave={saveHandler} categoryList={categoryList}/>
            </Modal>
        </Fragment>
    )
}

ClassEditButton.propTypes = {
    record: instanceOf(ClassObject),
    onSave: func,
    categoryList: arrayOf(instanceOf(CategoryObject))
}