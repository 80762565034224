import {Button, Form, Input, Switch} from "antd";
import React, {useState} from "react";
import RichTextEditor from "../shared/form/RichTextEditor";
import {bool, func, instanceOf} from "prop-types";
import {JobObject} from "../../models/Job";

/**@param {JobObject} editPayload
 * @param {Function} onSave
 * @param {Boolean} isLoading
 */
export default function JobAddEditModal({editPayload, onSave, isLoading}) {
    const [imgName, setImgName] = useState("");
    const [imgFile, setImgFile] = useState(null);

    const onSubmit = (payload) => {
        onSave && onSave({...editPayload, ...payload, imgName}, imgFile);
    }

    const getInitialValues = () => {
        if (editPayload) {
            return {
                title: editPayload.title,
                description: editPayload.description,
                location: editPayload.location,
                position: editPayload.position,
                isOpen: editPayload.isOpen
            }
        } else {
            return {}
        }
    }

    const getFileName = (event) => {
        //eslint-disable-next-line
        setImgName(event.target.value.replace(/^.*[\\\/]/, ''));
        setImgFile(event.target.files[0]);
    }

    return (
        <div>
            <h1>{editPayload ? "Sửa việc làm" : "Thêm việc làm"}</h1>
            <div>
                <Form labelCol={{span: 7}}
                      wrapperCol={{span: 13}}
                      onFinish={onSubmit}
                      name="event-form"
                      initialValues={getInitialValues()}
                >
                    <Form.Item
                        name="title"
                        label="Tên việc làm"
                        rules={[{required: true, message: "Bạn phải nhập tên việc làm"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="position"
                        label="Vị trí tuyển dụng"
                        rules={[{required: true, message: "Bạn phải nhập vị trí"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="location"
                        label="Nơi làm việc"
                        rules={[{required: true, message: "Bạn phải nhập nơi làm việc"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="url"
                        label="Form đăng ký"
                    >
                        <Input placeholder="Google Forms,..."/>
                    </Form.Item>
                    <Form.Item name="description" label="Miêu tả">
                        <RichTextEditor config={{
                            minHeight: 300,
                            tabIndex: 0,
                            uploader: {
                                "insertImageAsBase64URI": true
                            }
                        }}/>
                    </Form.Item>
                    <Form.Item name="imgUrl" label="Hình nền">
                        <Input type="file" accept="image/*" onChange={getFileName}/>
                    </Form.Item>
                    <Form.Item name="isOpen" label="Trạng thái" valuePropName="checked">
                        <Switch checkedChildren="Mở" unCheckedChildren="Đóng"/>
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Lưu việc làm</Button>
                </Form>
            </div>
        </div>
    )
}

JobAddEditModal.propTypes = {
    editPayload: instanceOf(JobObject),
    onSave: func,
    isLoading: bool
}