import {Button, Checkbox, Col, Form, Input, InputNumber, Row} from "antd";
import React from "react";
import {bool, func, instanceOf} from "prop-types";
import {CategoryObject} from "../../models/Category";
import {CategoryFilters} from "../../utils/constants";
import {getCategoryLabel} from "../../utils/helpers";
import styles from './CategoryPageAddEditModal.module.scss';
import flatten, {unflatten} from 'flat';

/**@param {CategoryObject} editPayload
 * @param {Function} onSave
 * @param {Boolean} isLoading
 */
export default function CategoryAddEditModal({editPayload, onSave, isLoading}) {
    const onSubmit = (payload) => {
        onSave && onSave({...editPayload, ...unflatten(payload)});
    }

    const getInitialValues = () => {
        return editPayload
            ? {...flatten(editPayload), limit: editPayload.limit}
            : {sortKey: 0}
    };

    return (
        <div>
            <h1>{editPayload ? "Sửa danh mục" : "Thêm danh mục"}</h1>
            <div>
                <Form labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      onFinish={onSubmit}
                      name="event-form"
                      initialValues={getInitialValues()}
                >
                    <Form.Item
                        name="title"
                        label="Tên danh mục"
                        rules={[{required: true, message: "Bạn phải nhập tên danh mục"}]}
                    >
                        <Input placeholder="Tiếng anh Tiểu học, IELTS,..."/>
                    </Form.Item>
                    <Form.Item name="limit" label="Giới hạn">
                        <Checkbox.Group className={styles.checkBoxGroup}>
                            {Object.values(CategoryFilters).map(cat => (
                                <Row key={cat} className={styles.checkBoxRow}>
                                    <Col span={10}>
                                        <Checkbox value={cat}>{getCategoryLabel(cat)}</Checkbox>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name={`description.${cat}`}
                                            dependencies={['limit']}
                                        >
                                            <Input placeholder={`Miêu tả cho ${getCategoryLabel(cat)}`}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item
                        name="sortKey"
                        label="Thứ tự"
                        tooltip="Thứ tự hiển thị (nhỏ hơn hiện trước)"
                    >
                        <InputNumber/>
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Lưu danh mục</Button>
                </Form>
            </div>
        </div>
    )
}

CategoryAddEditModal.propTypes = {
    editPayload: instanceOf(CategoryObject),
    onSave: func,
    isLoading: bool
}