import {Button, Form, Input, Select} from "antd";
import React, {useState} from "react";
import {arrayOf, bool, func, instanceOf} from "prop-types";
import {CourseObject} from "../../models/CourseObject";
import {CategoryObject} from "../../models/Category";
import {CategoryFilters} from "../../utils/constants";
import TextArea from "antd/es/input/TextArea";
import HtmlContent from "../shared/form/HtmlContent";
import {cloneDeep, isEmpty} from "lodash/lang";
import {handleFileUpload} from "../../utils/helpers";

/**@param {CourseObject} editPayload
 * @param {Function} onSave
 * @param {Boolean} isLoading
 * @param {CategoryObject[]} categoryList
 */
export default function CourseAddEditModal({editPayload, onSave, isLoading, categoryList}) {
    const _categoryList = categoryList.filter(cat => cat.limit?.includes(CategoryFilters.COURSES) || isEmpty(cat))

    const isEdit = Boolean(editPayload);

    const [imgName, setImgName] = useState("");
    const [imgFile, setImgFile] = useState(null);


    const onSubmit = async (payload) => {
        const htmlContent = payload.htmlFile ? await handleFileUpload(payload.htmlFile[0]?.originFileObj) : '';

        onSave && onSave({...editPayload, ...payload, imgName, htmlContent}, imgFile);
    }

    const getInitialValues = () => {
        if (!editPayload) return {categoryId: editPayload?.categoryId || _categoryList[0]?.id}

        const _payload = cloneDeep(editPayload);
        delete _payload['imgUrl'];
        return _payload;
    }

    const getFileName = (event) => {
        //eslint-disable-next-line
        setImgName(event.target.value.replace(/^.*[\\\/]/, ''));
        setImgFile(event.target.files[0]);
    }

    return (
        <div>
            <h1>{isEdit ? "Sửa khóa học" : "Thêm khóa học"}</h1>
            <div>
                <Form labelCol={{span: 8}}
                      wrapperCol={{span: 12}}
                      onFinish={onSubmit}
                      name="course-form"
                      initialValues={getInitialValues()}
                >
                    <Form.Item
                        name="title"
                        label="Tên khóa học"
                        rules={[{required: true, message: "Bạn phải nhập tên khóa học"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="categoryId"
                        label="Danh mục"
                        rules={[{required: true, message: "Bạn phải chọn danh mục"}]}
                    >
                        <Select style={{width: 200}}>
                            {_categoryList.map(opt => (
                                    <Select.Option value={opt.id} key={opt.id}>
                                        {opt.title}
                                    </Select.Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item name="description" label="Miêu tả">
                        <TextArea/>
                    </Form.Item>
                    <HtmlContent/>
                    <Form.Item
                        name="imgUrl"
                        label="Hình nền"
                    >
                        <Input type="file" accept="image/*" onChange={getFileName}/>
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Lưu khóa học</Button>
                </Form>
            </div>
        </div>
    )
}

CourseAddEditModal.propTypes = {
    editPayload: instanceOf(CourseObject),
    onSave: func,
    isLoading: bool,
    categoryList: arrayOf(instanceOf(CategoryObject))
}