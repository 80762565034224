import {ApiBase} from "./ApiBase";
import {ImageGalleryObject} from "../../models/ImageGalleryObject";

export class ApiImageGallery extends ApiBase {
    _getPayload(payload) {
        return {
            id: payload.id,
            title: payload.title,
            profile_image: payload.imgData[0]?.name
        }
    }

    getImageGalleryList() {
        return this._requester.get('/gallery/list')
            .then(res => res.data.data.map(img => new ImageGalleryObject(img)))
            .catch(() => [])
    }

    addImage(payload) {
        return this._requester.post('/gallery', this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error)
    }

    deleteImage(slug) {
        return this._requester.delete('/gallery/' + slug);
    }
}
