import React from 'react';
import styles from "./BasePage.module.css";
import Sidebar from "../sidebar/Sidebar";
import TopBar from "../topbar/TopBar";
import {any, bool} from "prop-types";

export default function BasePage({children, isLoading}) {
    return (
        <div className={styles.wrapper}>
            <Sidebar/>
            <div className={styles.rightSection}>
                <TopBar/>
                <div className={styles.mainContentWrapper}>
                    {children}
                </div>
            </div>
            {isLoading &&
            <div className={styles.loadingIndicator}>
                <i className="bi bi-arrow-repeat"/>
            </div>}
        </div>
    )
}

BasePage.propTypes = {
    children: any,
    isLoading: bool
}