import {ApiBase, HttpMethods} from "./ApiBase";
import {CategoryObject} from "../../models/Category";

export class ApiCategory extends ApiBase {
    /**@return {Promise<CategoryObject[]>}*/
    getCategoryList() {
        return this._requester.get("/category/list")
            .then(res => res.data.data.map(c => new CategoryObject(c)))
            .catch(e => {
                console.error(e);
                return [];
            });
    }

    /**@param {CategoryObject} payload
     * @return {Promise}*/
    addCategory(payload) {
        return this._generateRequest("/category", HttpMethods.POST, this._getPayload(payload))
            .then(fetch)
            .then(this._handleResponse);
    }

    /**@param {CategoryObject} payload*/
    editCategory(payload) {
        return this._generateRequest("/category", HttpMethods.PUT, this._getPayload(payload))
            .then(fetch)
            .then(this._handleResponse);
    }

    deleteCategory(categorySlug) {
        return this._generateRequest("/category/" + categorySlug, HttpMethods.DELETE)
            .then(fetch)
            .then(this._handleNonJSONResponse);
    }
}
