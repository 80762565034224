import {ApiEvent} from "./ApiEvent";
import {ApiCategory} from "./ApiCategory";
import {ApiJob} from "./ApiJob";
import {ApiLesson} from "./ApiLesson";
import {ApiRegistration} from "./ApiRegistration";
import ApiNews from "./ApiNews";
import {ApiClass} from "./ApiClass";
import ApiExam from "./ApiExam";
import {ApiBanner} from "./ApiBanner";
import ApiTeachingMethod from "./ApiTeachingMethod";
import {ApiLanding} from "./ApiLanding";
import {ApiImageGallery} from "./ApiImageGallery";
import {ApiCourse} from "./ApiCourse";

const ApiServices = {
    banner: new ApiBanner(),
    event: new ApiEvent(),
    category: new ApiCategory(),
    lesson: new ApiLesson(),
    class: new ApiClass(),
    job: new ApiJob(),
    registrations: new ApiRegistration(),
    news: new ApiNews(),
    exam: new ApiExam(),
    method: new ApiTeachingMethod(),
    landing: new ApiLanding(),
    imageGallery: new ApiImageGallery(),
    course: new ApiCourse()
}

Object.freeze(ApiServices);
export default ApiServices;