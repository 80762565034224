import imageCompression from "browser-image-compression";
import axios from "axios";
import {snakeCase} from "lodash/string";
import FbServices from "../firebaseservice";

/**@enum {String}*/
export const HttpMethods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE"
}

export class ApiBase {
    constructor() {
        this._baseUrl = process.env.REACT_APP_BASE_URL;
        this._requester = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL
        })

        this._requester.interceptors.request.use((config) => {
            config.headers.Authorization = 'Bearer ' + this.getAccessToken();
            return config;
        });
    }

    getAccessToken() {
        const userCreds = JSON.parse(localStorage.getItem('user_creds'));
        return userCreds?.accessToken;
    }

    /**@param {String} url
     * @param {HttpMethods?}method
     * @param {Object?} payload
     */
    async _generateRequest(url = '', method = HttpMethods.GET, payload) {
        const token = await FbServices.auth.getAccessToken();

        const options = {
            method: method,
            headers: {
                Authorization: 'Bearer ' + token
            }
        };
        if (payload) {
            options['body'] = JSON.stringify(payload)
        }
        return new Request(this._baseUrl + url, options);
    }

    /**@param {String} url
     * @param {Object} payload*/
    async _generateGetRequestWQuery(url, payload) {
        const token = this.getAccessToken();

        const options = {
            headers: {
                Authorization: token
            }
        }

        return new Request(`${this._baseUrl}${url}?` + new URLSearchParams(payload), options);
    }

    _handleResponse(res) {
        return res.ok ? res.json() : res.error();
    }

    _handleNonJSONResponse(res) {
        return res.ok ? "" : res.error();
    }

    /**@param {String} url
     * @param {File} file
     * @param {Object} option
     * @return {Promise<void>}*/
    uploadImage(url, file, option = {}) {
        const _uploadFile = this.uploadFile.bind(this);

        return imageCompression(file, option).then(_file => _uploadFile(url, _file)).catch(console.error);
    }

    uploadFile(url, file) {
        return fetch(url, {
            method: HttpMethods.PUT,
            headers: {
                'Content-Type': file.type
            },
            body: file
        })
    }

    /**@param {Moment} dateTime
     * @return {String}*/
    _formatDate(dateTime) {
        return dateTime.format('yyyy-MM-DD HH:mm:ss');
    }

    _getPayload(payload) {
        const result = {}
        Object.keys(payload).forEach(key => {
            if (['htmlFile', 'imgUrl'].includes(key)) return;

            if (!payload[key]) return;

            if (key === 'imgName') {
                return result['profile_image'] = payload[key];
            }

            if (key === 'imgData' && payload.imgData?.length) {
                return result['profile_image'] = payload.imgData[0].name;
            }

            if (key === 'imgDataLarge' && payload.imgDataLarge?.length) {
                return result['large_img'] = payload.imgDataLarge[0].name;
            }

            if (key === 'imgDataSmall' && payload.imgDataSmall?.length) {
                return result['small_img'] = payload.imgDataSmall[0].name;
            }

            result[snakeCase(key)] = payload[key];
        })

        return result;
    }
}
