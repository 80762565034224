import {BaseObject} from "./BaseObject";

export class TeachingMethodObject extends BaseObject {
    constructor(data) {
        super(data);
        this.tag = data.tag || "";
        this.content = data.content || "";
        this.author = data.author || "";
        this.description = data.description || "";
    }
}