import {Button, Form, Input, Modal, Select, Space} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useState} from "react";
import {getFormsUrl} from "../../utils/helpers";
import {arrayOf, bool, func, instanceOf} from "prop-types";
import {ExamObject} from "../../models/ExamObject";
import {CategoryObject} from "../../models/Category";
import {CategoryFilters} from "../../utils/constants";
import {isEmpty} from "lodash/lang";

export default function ExamAddEditModal({editPayload, onSave, isLoading, categoryList}) {
    const [imgName, setImgName] = useState("");
    const [imgFile, setImgFile] = useState(null);

    const [showExplanation, setShowExplanation] = useState(false);

    const _categoryList = categoryList.filter(cat => cat.limit?.includes(CategoryFilters.EXAMS) || isEmpty(cat));

    const onSubmit = (payload) => {
        const _payload = {
            ...payload,
            formsUrl: getFormsUrl(payload.formsUrl)
        }

        onSave && onSave({...editPayload, ..._payload, imgName}, imgFile);
    }

    const getInitialValues = () => {
        if (!editPayload) return {};
        return {
            title: editPayload.title,
            description: editPayload.description,
            formsUrl: editPayload.formsUrl,
            categoryId: editPayload.categoryId
        }
    }

    const getFileName = (event) => {
        //eslint-disable-next-line
        setImgName(event.target.value.replace(/^.*[\\\/]/, ''));
        setImgFile(event.target.files[0]);
    }

    const toggleExplanation = () => setShowExplanation(!showExplanation);

    return (
        <div>
            <h1>{editPayload ? "Sửa bài kiểm tra" : "Thêm bài kiểm tra"}</h1>
            <div>
                <Form labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      onFinish={onSubmit}
                      name="event-form"
                      initialValues={getInitialValues()}
                >
                    <Form.Item
                        name="title"
                        label="Tiêu đề"
                        rules={[{required: true, message: "Bạn phải nhập tiêu đề"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name="description" label="Miêu tả ngắn">
                        <TextArea/>
                    </Form.Item>
                    <Form.Item
                        name="formsUrl"
                        label="Form kiểm tra trình độ"
                    >
                        <Input placeholder="Đường dẫn google forms"/>
                    </Form.Item>
                    <Form.Item
                        name="categoryId"
                        label="Danh mục"
                        rules={[{required: true, message: "Bạn phải chọn danh mục"}]}
                    >
                        <Select style={{width: 200}}>
                            {_categoryList.map(opt => (
                                    <Select.Option value={opt.id} key={opt.id}>
                                        {opt.title}
                                    </Select.Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="imgUrl"
                        label="Hình nền"
                    >
                        <Input type="file" accept="image/*" onChange={getFileName}/>
                    </Form.Item>
                    <Space size="small">
                        <Button loading={isLoading} type="primary" htmlType="submit">Lưu bài kiểm tra</Button>
                        <Button type="link" onClick={toggleExplanation}>
                            Lưu ý: Chỉ nhập đường dẫn Google Forms Embed URL.
                        </Button>
                        <Modal visible={showExplanation} onCancel={toggleExplanation} footer={null} width={800}
                               destroyOnClose>
                            <h1>Hướng dẫn lấy Embed URL cho Google Forms</h1>
                            <img src="/google-forms-embed.jpg" alt="" width="100%"/>
                        </Modal>
                    </Space>
                </Form>
            </div>
        </div>
    )
}

ExamAddEditModal.propTypes = {
    editPayload: instanceOf(ExamObject),
    onSave: func,
    isLoading: bool,
    categoryList: arrayOf(instanceOf(CategoryObject))
}
