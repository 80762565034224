import {BaseObject} from "./BaseObject";

export class CourseObject extends BaseObject {
    constructor(data) {
        super(data);
        this.categoryId = data.categoryId || data.category_id || "";
        this.description = data.description || "";
        this.htmlContent = data.html_content || data.htmlContent || "";
    }
}
