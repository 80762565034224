export const PathNames = {
    HomePage: "/",
    FEHome: "/home",
    Login: "/login",
    Banner: "/banner",
    LearningPaths: '/learning-paths',
    Event: "/event",
    Category: "/category",
    Courses: "/courses",
    Class: "/class",
    Lesson: "/lesson",
    Exams: "/exams",
    Jobs: "/job",
    Registrations: "/registration",
    News: "/news",
    TeachingMethods: "/teaching-method",
    ImageGallery: "/image-gallery"
}