/**@enum {String}*/
export const LessonLevel = {
    BASIC: "basic",
    INTERMEDIATE: "intermediate",
    ADVANCED: "advanced"
}

/**@enum {String}*/
export const LessonType = {
    GRAMMAR: "grammar",
    VOCABULARY: "vocabulary",
    EXAM: "exam"
}

/**@enum {String}*/
export const LandingPageKeys = {
    // HEADER
    INTRODUCTION: "introductions",
    CORE_VALUES: "core_values",
    COURSES: "courses",
    EXAMS: "exams",
    LEARNING_PATHS: "learning_paths",

    // HOME PAGE
    SPECIAL_METHODS: "special_methods",
    EXTRAORDINARY_PROGRAMS: "extraordinary_programs",
    REGISTRATION_PROCESS: "registration_process"
}

export const CategoryFilters = {
    COURSES: "courses",
    EXAMS: "exams",
    CLASSES: "classes"
}
