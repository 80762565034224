import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import React, {Fragment, useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import {Toast} from "../../utils/toast";
import NewsAddEditModal from "./NewsAddEditModal";
import {NewsObject} from "../../models/NewsObject";
import {truncate} from "lodash";
import {stripHtml} from "../../utils/helpers";
import {func, instanceOf} from "prop-types";

function NewsEditButton({record, onEditSuccess}) {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleEditButton = () => {
        setIsEdit(!isEdit);
    }

    const onEditEvent = (payload, imageFile) => {
        setIsLoading(true);
        ApiServices.news.editNews(payload)
            .then(res => {
                return imageFile ? ApiServices.news.uploadImage(res.profile_image_url_put, imageFile) : null;
            })
            .then(() => {
                Toast.showSuccess("Sửa tin tức thành công.");
                toggleEditButton();
                onEditSuccess && onEditSuccess(new NewsObject({...record, ...payload}))
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    return (
        <Fragment>
            <Button type="primary" onClick={toggleEditButton}>Sửa</Button>
            <Modal visible={isEdit} footer={null} onCancel={toggleEditButton} width={1000} destroyOnClose>
                <NewsAddEditModal editPayload={record} onSave={onEditEvent} isLoading={isLoading}/>
            </Modal>
        </Fragment>
    )
}

export default function NewsPage() {
    const [newsList, setNewsList] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiServices.news.getNewsList()
            .then(setNewsList)
            .catch(console.error)
            .finally(() => setIsLoading(false));
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleAddNews = () => {
        setIsShowModal(!isShowModal);
    }

    const onAddNews = (payload, imageFile) => {
        setIsLoading(true);
        let slug;
        ApiServices.news.addNews(payload)
            .then(res => {
                slug = res.slug
                return imageFile ? ApiServices.event.uploadImage(res.profile_image_url_put, imageFile) : null;
            })
            .then(() => {
                const newEvent = new NewsObject({...payload, slug});
                setNewsList([newEvent, ...newsList]);
                Toast.showSuccess("Tạo sự kiện thành công.");
                toggleAddNews();
            })
            .catch(() => Toast.showError)
            .finally(() => setIsLoading(false));
    }

    const onEditItemSuccess = (payload) => {
        setNewsList(newsList.map(e => {
            return e.id === payload.id ? payload : e;
        }));
    }

    /**@param {NewsObject} newsObject*/
    const onClickDeleteItem = (newsObject) => {
        ApiServices.news.deleteNews(newsObject.slug)
            .then(() => Toast.showSuccess("Xóa tin tức thành công"))
            .then(() => setNewsList(newsList.filter(ev => ev.id !== newsObject.id)))
            .catch(() => Toast.showError());
    }

    const columns = [
        {
            title: 'Hình nền',
            dataIndex: 'profileImg',
            key: 'profileImg',
            render: (text, record) => <img src={record.imgUrl} alt="" width={50}/>
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Miêu tả',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => truncate(stripHtml(record.description))
        },
        {
            title: 'Tác giả',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <NewsEditButton record={record} onEditSuccess={onEditItemSuccess}/>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Tin tức"/>
            <div className="page__title">
                <h1>Tin tức</h1>
                <Button
                    type="text"
                    className="button--add"
                    onClick={toggleAddNews}
                >
                    Thêm tin tức
                </Button>
            </div>
            <Table dataSource={newsList} columns={columns}/>
            <Modal visible={isShowModal} footer={null} onCancel={toggleAddNews} width={1000} destroyOnClose>
                <NewsAddEditModal onSave={onAddNews} isLoading={isLoading}/>
            </Modal>
        </BasePage>
    )
}

NewsEditButton.propTypes = {
    record: instanceOf(NewsObject),
    onEditSuccess: func
}