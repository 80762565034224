import React, {Fragment, useState} from "react";
import {Button, Modal} from "antd";
import LessonAddEditModal from "./LessonAddEditModal";
import {arrayOf, func, instanceOf} from "prop-types";
import {LessonObject} from "../../models/LessonObject";
import {ClassObject} from "../../models/ClassObject";

/**@param {LessonObject} record
 * @param {Function} onSave
 * @param {ClassObject[]} classList*/
export function LessonEditButton({record, onSave, classList}) {
    const [isEdit, setIsEdit] = useState(false);

    const toggleEditButton = () => {
        setIsEdit(!isEdit);
    }

    const onSaveHandler = (...params) => {
        return onSave(...params).then(toggleEditButton);
    }

    return (
        <Fragment>
            <Button type="primary" onClick={toggleEditButton}>Sửa</Button>
            <Modal visible={isEdit} footer={null} onCancel={toggleEditButton} width={1000} destroyOnClose>
                <LessonAddEditModal lessonDetail={record} onSave={onSaveHandler} classList={classList}/>
            </Modal>
        </Fragment>
    )
}

LessonEditButton.propTypes = {
    record: instanceOf(LessonObject),
    onSave: func,
    classList: arrayOf(instanceOf(ClassObject))
}