import {ApiBase, HttpMethods} from "./ApiBase";
import {ClassObject} from "../../models/ClassObject";

export class ApiClass extends ApiBase {
    /**@return {Promise<Array<ClassObject>>}*/
    getClassList() {
        return this._generateRequest('/class_item/list')
            .then(fetch)
            .then(this._handleResponse)
            .then(res => res.data.map(ls => new ClassObject(ls)))
    }

    addClass(payload) {
        return this._requester.post("/class_item", this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    editClass(payload) {
        return this._requester.put("/class_item", this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    deleteClass(slug) {
        return this._generateRequest("/class_item/" + slug, HttpMethods.DELETE)
            .then(fetch)
            .then(this._handleNonJSONResponse);
    }
}
