import {Button, Form, Input, InputNumber, Modal, Select, Space} from "antd";
import React, {useRef, useState} from "react";
import {getFormsUrl} from "../../utils/helpers";
import RichTextEditor from "../shared/form/RichTextEditor";
import {arrayOf, bool, func, instanceOf} from "prop-types";
import {ClassObject} from "../../models/ClassObject";
import {CategoryObject} from "../../models/Category";
import {CategoryFilters} from "../../utils/constants";

/**@param {ClassObject} editPayload
 * @param {Function} onSave
 * @param {Boolean} isLoading
 * @param {CategoryObject[]} categoryList
 */
export default function ClassAddEditModal({editPayload, onSave, isLoading, categoryList}) {
    const isEdit = Boolean(editPayload);

    const formRef = useRef(null);

    const [imgName, setImgName] = useState("");
    const [imgFile, setImgFile] = useState(null);

    const [showExplanation, setShowExplanation] = useState(false);

    const onSubmit = (payload) => {
        const _payload = {
            ...payload,
            formsUrl: getFormsUrl(payload.formsUrl)
        }

        onSave && onSave({...editPayload, ..._payload, imgName}, imgFile);
    }

    const getInitialValues = () => {
        const getCategory = () => {
            if (!(categoryList && categoryList.length)) {
                return "";
            }

            if (editPayload) {
                return editPayload.categoryId;
            }

            return categoryList[0].id
        }

        if (isEdit) {
            return {
                ...editPayload,
                categoryId: getCategory()
            }
        } else {
            return {
                categoryId: getCategory()
            }
        }
    }

    const getFileName = (event) => {
        //eslint-disable-next-line
        setImgName(event.target.value.replace(/^.*[\\\/]/, ''));
        setImgFile(event.target.files[0]);
    }

    const toggleExplanation = () => setShowExplanation(!showExplanation);

    return (
        <div>
            <h1>{isEdit ? "Sửa chương trình học" : "Thêm chương trình học"}</h1>
            <div>
                <Form labelCol={{span: 8}}
                      wrapperCol={{span: 12}}
                      onFinish={onSubmit}
                      name="event-form"
                      ref={formRef}
                      initialValues={getInitialValues()}
                >
                    <Form.Item
                        name="title"
                        label="Tên chương trình học"
                        rules={[{required: true, message: "Bạn phải nhập tên chương trình học"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="categoryId"
                        label="Danh mục"
                        rules={[{required: true, message: "Bạn phải chọn danh mục"}]}
                    >
                        <Select style={{width: 200}}>
                            {categoryList
                                .filter(cat => cat.limit?.includes(CategoryFilters.CLASSES))
                                .map(opt => (
                                        <Select.Option value={opt.id} key={opt.id}>
                                            {opt.title}
                                        </Select.Option>
                                    )
                                )}
                        </Select>
                    </Form.Item>
                    <Form.Item name="description" label="Miêu tả">
                        <RichTextEditor config={{
                            minHeight: 300,
                            minWidth: 600,
                            tabIndex: 0,
                            uploader: {
                                "insertImageAsBase64URI": true
                            }
                        }}/>
                    </Form.Item>
                    <Form.Item
                        name="formsUrl"
                        label="Form kiểm tra trình độ"
                    >
                        <Input placeholder="Đường dẫn google forms"/>
                    </Form.Item>
                    <Form.Item
                        name="duration"
                        label="Thời lượng (giờ)"
                    >
                        <InputNumber min={0}/>
                    </Form.Item>
                    <Form.Item
                        name="teacher"
                        label="Giảng viên"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="imgUrl"
                        label="Hình nền"
                    >
                        <Input type="file" accept="image/*" onChange={getFileName}/>
                    </Form.Item>
                    <Space size="small">
                        <Button loading={isLoading} type="primary" htmlType="submit">Lưu chương trình học</Button>
                        <Button type="link" onClick={toggleExplanation}>
                            Lưu ý: Chỉ nhập đường dẫn Google Forms Embed URL.
                        </Button>
                        <Modal visible={showExplanation} onCancel={toggleExplanation} footer={null} width={800}
                               destroyOnClose>
                            <h1>Hướng dẫn lấy Embed URL cho Google Forms</h1>
                            <img src="/google-forms-embed.jpg" alt="" width="100%"/>
                        </Modal>
                    </Space>
                </Form>
            </div>
        </div>
    )
}

ClassAddEditModal.propTypes = {
    editPayload: instanceOf(ClassObject),
    onSave: func,
    isLoading: bool,
    categoryList: arrayOf(instanceOf(CategoryObject))
}