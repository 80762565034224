export class BaseObject {
    constructor(data) {
        this.id = data.id;
        this.key = data.key || data.id || data.slug;
        this.slug = data.slug;
        this.imgUrl = data.profile_image_url_get || data.profile_image_url || data.imgUrl || "";
        this.title = data.title || "";
        this.createdAt = data.createdAt || new Date(data.created_at) || "";
        this.imgName = data.imgName || "";
        this.imgData = data.imgData || "";
    }
}
