import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import "bootstrap-icons/font/bootstrap-icons.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./store";

require("dotenv").config({
    path: `.env.${process.env.NODE_ENV}`,
})
ReactDOM.render(
    <div>
        <Provider store={store}>
            <App/>
        </Provider>
    </div>,
    document.getElementById('root')
);

reportWebVitals();
