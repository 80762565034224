import React, {Fragment, useState} from "react";
import ApiServices from "../../services/apiservices";
import {Toast} from "../../utils/toast";
import {Button, Modal} from "antd";
import ExamAddEditModal from "./ExamAddEditModal";
import {ExamObject} from "../../models/ExamObject";
import {arrayOf, func, instanceOf} from "prop-types";
import {CategoryObject} from "../../models/Category";

export default function ExamEditButton({record, onEditSuccess, categoryList = []}) {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleEditButton = () => {
        setIsEdit(!isEdit);
    }

    const onEditExam = (payload, imageFile) => {
        setIsLoading(true);
        ApiServices.exam.editExam(payload)
            .then(async res => {
                imageFile && await ApiServices.exam.uploadImage(res.profile_image_url_put, imageFile);
                return res;
            })
            .then((res) => {
                Toast.showSuccess("Sửa bài kiểm tra thành công.");
                toggleEditButton();
                onEditSuccess && onEditSuccess(new ExamObject({...record, ...payload, ...res}))
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    return (
        <Fragment>
            <Button type="primary" onClick={toggleEditButton}>Sửa</Button>
            <Modal visible={isEdit} footer={null} onCancel={toggleEditButton} width={1000} destroyOnClose>
                <ExamAddEditModal editPayload={record} onSave={onEditExam} isLoading={isLoading}
                                  categoryList={categoryList}/>
            </Modal>
        </Fragment>
    )
}

ExamEditButton.propTypes = {
    record: instanceOf(ExamObject),
    onEditSuccess: func,
    categoryList: arrayOf(instanceOf(CategoryObject))
}