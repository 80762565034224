import {ApiBase, HttpMethods} from "./ApiBase";
import {TeachingMethodObject} from "../../models/TeachingMethodObject";

export default class ApiTeachingMethod extends ApiBase {
    /**@return {Promise<TeachingMethodObject[]>}*/
    getTeachingMethodList() {
        return this._generateRequest("/teaching_method/list")
            .then(fetch)
            .then(this._handleResponse)
            .then(res => res.data.map(tm => new TeachingMethodObject(tm)));
    }

    _generatePayload(payload) {
        const _payload = {
            title: payload.title,
            description: payload.description,
            content: payload.content,
            author: payload.author
        }

        if (payload.imgName) {
            _payload['profile_image'] = payload.imgName;
        }

        if (payload['id']) {
            _payload['id'] = payload.id;
        }

        return _payload;
    }

    /**@param {TeachingMethodObject} payload*/
    addTeachingMethod(payload) {
        return this._requester.post("/teaching_method", this._generatePayload(payload))
            .then(res => res.data.data);
    }

    /**@param {TeachingMethodObject} payload*/
    editTeachingMethod(payload) {
        return this._requester.put("/teaching_method", this._generatePayload(payload))
            .then(res => res.data.data);
    }

    /**@param {String} eventId*/
    deleteTeachingMethod(eventId) {
        return this._generateRequest("/teaching_method/" + eventId, HttpMethods.DELETE)
            .then(fetch)
            .then(this._handleNonJSONResponse);
    }
}
