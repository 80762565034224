import React, {useState} from "react";
import {Button, Form, Input, Select} from "antd";
import {LessonLevel, LessonType} from "../../utils/constants";
import {LessonObject} from "../../models/LessonObject";
import RichTextEditor from "../shared/form/RichTextEditor";
import {arrayOf, func, instanceOf} from "prop-types";
import {ClassObject} from "../../models/ClassObject";

/**@param {LessonObject} lessonDetail
 * @param {Function} onSave
 * @param {ClassObject[]} classList*/
export default function LessonAddEditModal({lessonDetail, onSave, classList}) {
    const [pdfName, setPdfName] = useState("");
    const [file, setFile] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const getCategoryId = (classId) => {
        const classObject = classList.find(cl => cl.id === classId);
        return classObject?.categoryId;
    }

    const onSubmit = (payload) => {
        setIsLoading(true);
        const _payload = new LessonObject({
            ...lessonDetail,
            ...payload,
            pdfName,
            classId: payload.classId,
            categoryId: getCategoryId(payload.classId)
        })
        onSave && onSave(_payload, file).finally(() => setIsLoading && setIsLoading(false))
    }

    const getFileName = (event) => {
        //eslint-disable-next-line
        setPdfName(event.target.value.replace(/^.*[\\\/]/, ''));
        setFile(event.target.files[0]);
    }

    const getInitialValues = () => {
        if (lessonDetail) {
            return {
                title: lessonDetail.title,
                classId: lessonDetail.classId,
                level: lessonDetail.level,
                type: lessonDetail.type,
                video: lessonDetail.video,
                text: lessonDetail.text
            }
        } else {
            return {
                level: LessonLevel.BASIC,
                type: LessonType.GRAMMAR
            }
        }
    }

    return (
        <div>
            <h1>{lessonDetail ? "Sửa bài học" : "Thêm bài học"}</h1>
            <Form labelCol={{span: 7}}
                  wrapperCol={{span: 13}}
                  onFinish={onSubmit}
                  name="event-form"
                  initialValues={getInitialValues()}
            >
                <Form.Item
                    name="title"
                    label="Tiêu đề"
                    rules={[{required: true, message: "Bạn phải nhập tiêu đề"}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="classId"
                    label="Chương trình học"
                    rules={[{required: true, message: "Bạn phải chọn chương trình học"}]}
                >
                    <Select style={{width: 400}}>
                        {classList.map(cl => (
                            <Select.Option value={cl.id} key={cl.id}>
                                {cl.title}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="level"
                    label="Trình độ"
                >
                    <Select style={{width: 400}}>
                        <Select.Option value={LessonLevel.BASIC}>Cơ bản</Select.Option>
                        <Select.Option value={LessonLevel.INTERMEDIATE}>Trung bình khá</Select.Option>
                        <Select.Option value={LessonLevel.ADVANCED}>Nâng cao</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Phân loại"
                >
                    <Select style={{width: 400}}>
                        <Select.Option value={LessonType.GRAMMAR}>Ngữ pháp</Select.Option>
                        <Select.Option value={LessonType.VOCABULARY}>Từ vựng</Select.Option>
                        <Select.Option value={LessonType.EXAM}>Kiểm tra</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="pdf" label="File PDF">
                    <Input type="file" accept="application/pdf" onChange={getFileName}/>
                </Form.Item>
                <Form.Item
                    name="video"
                    label="Youtube Video"
                >
                    <Input type="text" placeholder="Youtube URL"/>
                </Form.Item>
                <Form.Item name="text" label="Nội dung chữ">
                    <RichTextEditor config={{
                        minHeight: 350,
                        tabIndex: 0,
                        uploader: {
                            "insertImageAsBase64URI": true
                        }
                    }}/>
                </Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">Lưu bài học</Button>
            </Form>
        </div>
    )
}

LessonAddEditModal.propTypes = {
    lessonDetail: instanceOf(LessonObject),
    onSave: func,
    classList: arrayOf(instanceOf(ClassObject))
}