import {ApiBase, HttpMethods} from "./ApiBase";
import {NewsObject} from "../../models/NewsObject";

export default class ApiNews extends ApiBase {
    /**@return {Promise<NewsObject[]>}*/
    getNewsList() {
        return this._requester.get('/news/list')
            .then(res => res.data.data.map(n => new NewsObject(n)))
            .catch(() => []);
    }

    /**@param {String} id
     * @return {Promise<NewsObject>}*/
    getNewsDetail(id) {
        return this._requester.get("/news/" + id)
            .then(res => res.data.data)
            .then(res => new NewsObject(res));
    }

    /**@param {NewsObject} payload*/
    addNews(payload) {
        const _payload = {
            title: payload.title,
            description: payload.description,
            content: payload.content,
            author: payload.author
        }

        if (payload.imgName) {
            _payload['profile_image'] = payload.imgName;
        }

        return this._requester.post("/news", _payload)
            .then(res => res.data.data);
    }

    /**@param {NewsObject} payload*/
    editNews(payload) {
        return this._requester.put("/news", this._getPayload(payload))
            .then(res => res.data.data);
    }

    /**@param {String} eventId*/
    deleteNews(eventId) {
        return this._generateRequest("/news/" + eventId, HttpMethods.DELETE)
            .then(fetch)
            .then(this._handleNonJSONResponse);
    }
}
