import {ApiBase, HttpMethods} from "./ApiBase";
import {JobObject} from "../../models/Job";

export class ApiJob extends ApiBase {
    /**@return {Promise<JobObject[]>}*/
    getJobList() {
        return this._generateRequest("/job/list")
            .then(fetch)
            .then(this._handleResponse)
            .then(res => res.data.map(j => new JobObject(j)))
            .catch(console.error);
    }

    /**@param {JobObject} payload
     * @return {Promise}*/
    addJob(payload) {
        return this._requester.post("/job", this._getPayload(payload))
            .then(res => res.data.data);
    }

    /**@param {JobObject} payload*/
    editJob(payload) {
        return this._requester.put("/job", this._getPayload(payload))
            .then(res => res.data.data);
    }

    /**@param {String} jobSlug*/
    deleteJob(jobSlug) {
        return this._generateRequest("/job/" + jobSlug, HttpMethods.DELETE)
            .then(fetch)
            .then(this._handleNonJSONResponse);
    }
}
