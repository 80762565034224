import {ApiBase} from "./ApiBase";
import {CourseObject} from "../../models/CourseObject";

export class ApiCourse extends ApiBase {
    /**@return {Promise<Array<ClassObject>>}*/
    getCourseList() {
        return this._requester.get('/course/list')
            .then(res => res.data.data.map(cr => new CourseObject(cr)))
            .catch(console.error);
    }

    addCourse(payload) {
        return this._requester.post('/course', this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    editCourse(payload) {
        return this._requester.put('/course', this._getPayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    deleteCourse(slug) {
        return this._requester.delete('/course/' + slug);
    }
}
