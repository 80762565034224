import React, {useEffect, useState} from 'react';
import BasePage from "../shared/basepage/BasePage";
import ApiServices from "../../services/apiservices";
import {Table} from "antd";
import {LandingPageKeys} from "../../utils/constants";
import {LandingPageObject} from "../../models/LandingPageObject";
import {Toast} from "../../utils/toast";
import {LandingPagePreview} from "../shared/landingpages/LandingPagePreview";
import LandingPageEditButton from "../shared/landingpages/LandingPageEditButton";

export default function HomePage() {
    const [landingPages, setLandingPages] = useState([]);

    useEffect(() => {
        ApiServices.landing.getLandingPages().then(setLandingPages);
    }, []);

    const uploadImage = (url, data) => {
        return ApiServices.landing.uploadImage(url, data, {maxWidthOrHeight: 1500, initialQuality: 1})
    }

    /** @param {LandingPageObject} payload*/
    const addOrEditItem = (payload) => {
        const promise = payload.id
            ? ApiServices.landing.editLandingPage(payload)
            : ApiServices.landing.addLandingPage(payload);

        return promise
            .then(async res => {
                res.large_img_url_put && await uploadImage(res.large_img_url_put, payload.imgDataLarge[0].originFileObj);
                res.small_img_url_put && await uploadImage(res.small_img_url_put, payload.imgDataSmall[0].originFileObj);
                return res;
            })
            .then(res => {
                const _landingPages = payload.id
                    ? landingPages.map(ld => {
                        return ld.id === payload.id ? new LandingPageObject({...payload, ...res}) : ld;
                    })
                    : [...landingPages, new LandingPageObject({...payload, ...res})]

                setLandingPages(_landingPages);
                Toast.showSuccess("Cập nhật thành công")
            })
            .catch(() => Toast.showError());
    }

    const coreValues = landingPages.find(ld => ld?.key === LandingPageKeys.CORE_VALUES) || new LandingPageObject({
        key: LandingPageKeys.CORE_VALUES,
        title: 'Giá trị cốt lõi'
    });
    const specialMethods = landingPages.find(ld => ld?.key === LandingPageKeys.SPECIAL_METHODS) || new LandingPageObject({
        key: LandingPageKeys.SPECIAL_METHODS,
        title: "Phương pháp giảng dạy đặc biệt"
    });
    const registrationProcess = landingPages.find(ld => ld?.key === LandingPageKeys.REGISTRATION_PROCESS) || new LandingPageObject({
        key: LandingPageKeys.REGISTRATION_PROCESS,
        title: "Quy trình đăng ký"
    });

    const dataSource = [coreValues, specialMethods, registrationProcess];

    const columns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Hình ảnh',
            key: 'image',
            render: (text, record) => (
                <div key={record.id}>
                    {!!record.largeImgUrl && <img src={record.largeImgUrl} alt='' height={100}/>}
                    {!!record.smallImgUrl && <img src={record.smallImgUrl} alt='' height={100}/>}
                </div>
            )
        },
        {
            title: "Landing page",
            key: 'edit',
            render: (text, record) => (
                <LandingPagePreview htmlContent={record.htmlContent}/>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => <LandingPageEditButton record={record} addOrEditItem={addOrEditItem} multipleImg/>
        },
    ];

    return (
        <BasePage>
            <h1>Trang chủ</h1>
            <Table dataSource={dataSource} columns={columns}/>
        </BasePage>
    )
}
