import React from 'react';
import styles from "./Dashboard.module.css";
import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";

export default function Dashboard() {
    return (
        <BasePage>
            <PageTitle pageName="Trang chủ"/>
            <span className={styles.wrapper}>
               Đây sẽ là trang dashboard nhằm thống kê số lượt truy cập, tương tác, thông báo quan trọng,...<br/>
                (Trong phase sau)
            </span>
        </BasePage>
    )
}
