import React from 'react';
import styles from "./TopBar.module.css";
import {Button} from "antd";
import FbServices from "../../../services/firebaseservice";
import {useHistory} from "react-router-dom";
import {PathNames} from "../../../utils/pathNames";

export default function TopBar() {
    const history = useHistory();

    const onClickSignOutHandler = () => {
        FbServices.auth.logout()
            .then(() => history.push(PathNames.Login))
            .catch(console.error);
    }

    return (
        <div className={styles.wrapper}>
            <span>Hi, Admin</span>
            <Button type="default" onClick={onClickSignOutHandler}>
                Đăng xuất
            </Button>
        </div>
    )
}
