import {CategoryFilters} from "./constants";

export const stripHtml = str => str.replace(/(<([^>]+)>)/gi, "");

export const getFormsUrl = (formsUrl) => {
    if (formsUrl && formsUrl.includes("iframe")) {
        const matches = formsUrl.match(/src="(.*?)" /);
        return matches && matches.length ? matches[1] : formsUrl;
    } else {
        return formsUrl;
    }
}

export const extractFileName = (str) => {
    // eslint-disable-next-line
    return str.replace(/^.*[\\\/]/, '');
}

export const getDefaultImageList = (payload, imgAttr = 'imgUrl') => {
    return payload && payload[imgAttr]
        ? [{
            uid: payload[imgAttr],
            name: decodeURI(extractFileName(payload[imgAttr])),
            status: 'done',
            url: payload[imgAttr],
        }]
        : []
}

export const normFile = (e, payload, imgAttr) => {
    if (!e) return getDefaultImageList(payload, imgAttr);

    if (Array.isArray(e)) {
        return e || [];
    }

    return e && e.fileList;
};

/**@param {File} fileObj
 * @return {Promise<string>}*/
export const handleFileUpload = (fileObj) => {
    const reader = new FileReader();

    return new Promise(resolve => {
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsText(fileObj);
    })
}

export const getCategoryLabel = (type) => {
    switch (type) {
        case CategoryFilters.COURSES:
            return "Khóa học";
        case CategoryFilters.EXAMS:
            return "Kiểm tra";
        case CategoryFilters.CLASSES:
            return "Bài học online";
        default:
            return type;
    }
}
