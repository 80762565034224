import React from 'react';
import styles from "./Sidebar.module.css";
import {Link, useLocation} from "react-router-dom";
import {PathNames} from "../../../utils/pathNames";
import {Button} from "antd";
import {instanceOf} from "prop-types";

class SidebarItem {
    constructor(path) {
        this.path = path;
        this.icon = this._getBtnIcon(path);
        this.title = this._getBtnTitle(path);
    }

    _getBtnIcon(pathName) {
        switch (pathName) {
            case PathNames.HomePage:
                return <i className="bi bi-bar-chart-line-fill"/>;
            case PathNames.FEHome:
                return <i className="bi bi-house-fill"/>;
            case PathNames.Banner:
                return <i className="bi bi-image"/>;
            case PathNames.LearningPaths:
                return <i className="bi bi-signpost-fill"/>;
            case PathNames.Category:
                return <i className="bi bi-tags-fill"/>;
            case PathNames.Courses:
                return <i className="bi bi-card-heading"/>;
            case PathNames.Class:
                return <i className="bi bi-journal-bookmark"/>;
            case PathNames.Lesson:
                return <i className="bi bi-file-earmark-richtext"/>;
            case PathNames.Exams:
                return <i className="bi bi-journal-medical"/>;
            case PathNames.Jobs:
                return <i className="bi bi-briefcase-fill"/>;
            case PathNames.News:
                return <i className="bi bi-newspaper"/>;
            case PathNames.Registrations:
                return <i className="bi bi-envelope-fill"/>;
            case PathNames.TeachingMethods:
                return <i className="bi bi-vector-pen"/>;
            case PathNames.ImageGallery:
                return <i className="bi bi-images"/>;
            default:
                return "";
        }
    }

    _getBtnTitle(pathName) {
        switch (pathName) {
            case PathNames.HomePage:
                return "Dashboard";
            case PathNames.FEHome:
                return "Trang chủ";
            case PathNames.Banner:
                return "Banner";
            case PathNames.LearningPaths:
                return "Lộ trình học tập";
            case PathNames.Category:
                return "Danh mục";
            case PathNames.Courses:
                return "Khóa học";
            case PathNames.Class:
                return "Chương trình online";
            case PathNames.Lesson:
                return "Bài học";
            case PathNames.Exams:
                return "Kiểm tra trình độ";
            case PathNames.Jobs:
                return "Việc làm";
            case PathNames.News:
                return "Tin tức";
            case PathNames.Registrations:
                return "Học viên đăng ký"
            case PathNames.TeachingMethods:
                return "Phương pháp giảng dạy";
            case PathNames.ImageGallery:
                return "Hình ảnh lớp học";
            default:
                return pathName;
        }
    }
}

/**@param {SidebarItem} item*/
function SidebarButton({item}) {
    const location = useLocation();

    const getStyle = () => {
        return [
            styles.menuBtn,
            location.pathname === item.path ? styles.menuBtnFocused : ""
        ].join(" ");
    }

    return (
        <Button type="text" className={getStyle()}>
            <Link to={item.path}>
                {item.icon}
                {item.title}
            </Link>
        </Button>
    )
}

export default function Sidebar() {
    return (
        <div className={styles.wrapper}>
            <Link to={PathNames.HomePage} className={styles.logoWrapper}>
                <img alt="logo" src="/logo.svg" width={40} height={40}/>
                <h3>CoachingEnglish</h3>
            </Link>
            <div className={styles.btnList}>
                <SidebarButton item={new SidebarItem(PathNames.HomePage)}/>
                <SidebarButton item={new SidebarItem(PathNames.FEHome)}/>
                <SidebarButton item={new SidebarItem(PathNames.Banner)}/>
                <SidebarButton item={new SidebarItem(PathNames.Category)}/>
                <SidebarButton item={new SidebarItem(PathNames.LearningPaths)}/>
                <SidebarButton item={new SidebarItem(PathNames.Courses)}/>
                <SidebarButton item={new SidebarItem(PathNames.Class)}/>
                <SidebarButton item={new SidebarItem(PathNames.Lesson)}/>
                <SidebarButton item={new SidebarItem(PathNames.Exams)}/>
                <SidebarButton item={new SidebarItem(PathNames.Jobs)}/>
                <SidebarButton item={new SidebarItem(PathNames.News)}/>
                <SidebarButton item={new SidebarItem(PathNames.TeachingMethods)}/>
                <SidebarButton item={new SidebarItem(PathNames.Registrations)}/>
                <SidebarButton item={new SidebarItem(PathNames.ImageGallery)}/>
            </div>
        </div>
    )
}

SidebarButton.propTypes = {
    item: instanceOf(SidebarItem)
}