import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import BannerAddEditModal from "./BannerAddEditModal";
import {Toast} from "../../utils/toast";
import {BannerEditButton} from "./BannerEditButton";
import {BannerObject} from "../../models/BannerObject";
import {LandingPagePreview} from "../shared/landingpages/LandingPagePreview";

export default function BannerPage() {
    const [bannerList, setBannerList] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiServices.banner.getBannerList()
            .then(res => setBannerList(res))
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [])

    const toggleAddBanner = () => {
        setShowAdd(show => !show);
    }

    const handleUploadImage = (res, payload) => {
        const _newBanner = new BannerObject({
            ...payload,
            ...res
        });
        return Promise.all([
            payload.largeImgData && ApiServices.banner.uploadImage(
                res.large_img_url_put, payload.largeImgData[0].originFileObj,
                {maxWidthOrHeight: 2500, initialQuality: 1}
            ),
            payload.smallImgData && ApiServices.banner.uploadImage(
                res.small_img_url_put, payload.smallImgData[0].originFileObj,
                {maxWidthOrHeight: 2500, initialQuality: 1}
            )
        ].filter(Boolean)).then(() => _newBanner);
    }

    const addBanner = (payload) => {
        setIsLoading(true);
        return ApiServices.banner.addBanner(payload)
            .then(res => handleUploadImage(res, payload))
            .then(newBanner => {
                setBannerList(bannerList => [newBanner, ...bannerList]);
                toggleAddBanner();
                Toast.showSuccess("Thêm banner thành công");
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    const onEditBanner = (payload) => {
        setIsLoading(true);
        return ApiServices.banner.editBanner(payload)
            .then(res => handleUploadImage(res, payload))
            .then(newBanner => {
                setBannerList(bannerList => bannerList.map(banner => {
                    return banner.id === newBanner.id ? newBanner : banner;
                }));

                Toast.showSuccess('Sửa banner thành công.');
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    /**@param {BannerObject} banner*/
    const onClickDeleteItem = (banner) => {
        ApiServices.banner.deleteBanner(banner.id)
            .then(() => Toast.showSuccess("Xóa banner thành công."))
            .then(() => setBannerList(bannerList.filter(cat => cat.id !== banner.id)))
            .catch(() => Toast.showError());
    }

    const columns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Hình lớn',
            dataIndex: 'largeImgUrl',
            key: 'largeImgUrl',
            render: (text, record) => <img src={record.largeImgUrl} alt="" width={50}/>
        },
        {
            title: 'Hình nhỏ',
            dataIndex: 'smallImgUrl',
            key: 'smallImgUrl',
            render: (text, record) => <img src={record.smallImgUrl} alt="" width={50}/>
        },
        {
            title: 'Landing Page',
            dataIndex: 'htmlContent',
            key: 'htmlContent',
            render: (text, record) => (
                <LandingPagePreview htmlContent={record.htmlContent}/>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <BannerEditButton record={record} onSave={onEditBanner}/>
                    <Popconfirm
                        title="Bạn có chắc chắn xóa?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Banner"/>
            <div>
                <div className="page__title">
                    <h1>Banner</h1>
                    <Button
                        type="text"
                        className="button--add"
                        onClick={toggleAddBanner}
                    >
                        Thêm banner
                    </Button>
                </div>
                <Table dataSource={bannerList} columns={columns}/>
            </div>
            <Modal visible={showAdd} footer={null} onCancel={toggleAddBanner} width={1000} destroyOnClose>
                <BannerAddEditModal onSave={addBanner} isLoading={isLoading}/>
            </Modal>
        </BasePage>
    )
}
