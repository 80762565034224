import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import React, {useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import {Table} from "antd";
import {LessonLevel} from "../../utils/constants";

export default function RegistrationPage() {
    const [regList, setRegList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiServices.registrations.getRegistrationList()
            .then(setRegList)
            .catch(console.error)
            .finally(() => setIsLoading(false));
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: "Tên học viên",
            dataIndex: 'fullName',
            key: 'fullName'
        },
        {
            title: "Số điện thoại",
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: "Email",
            dataIndex: 'emailAddress',
            key: 'emailAddress'
        },
        {
            title: "Trình độ",
            dataIndex: 'level',
            key: 'level',
            render: (text, record) => {
                switch (record.level) {
                    case LessonLevel.BASIC:
                        return "Cơ bản";
                    case LessonLevel.INTERMEDIATE:
                        return "Trung bình khá";
                    case LessonLevel.ADVANCED:
                        return "Nâng cao";
                    default:
                        return record.level;
                }
            }
        },
        {
            title: "Ngày đăng ký",
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => record.createdAt.format('DD/MM/yyyy HH:mm')
        },
    ]


    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Học viên đăng ký"/>
            <div>
                <h1 className="page__title">
                    Học viên đăng ký
                </h1>
                <Table dataSource={regList} columns={columns}/>
            </div>
        </BasePage>
    )
}