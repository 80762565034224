import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import React, {Fragment, useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import {Toast} from "../../utils/toast";
import TeachingMethodAddEditModal from "./TeachingMethodAddEditModal";
import {truncate} from "lodash";
import {stripHtml} from "../../utils/helpers";
import {TeachingMethodObject} from "../../models/TeachingMethodObject";
import {func, instanceOf} from "prop-types";

function TeachingMethodEditButton({record, onEditSuccess}) {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleEditButton = () => {
        setIsEdit(!isEdit);
    }

    const onEditEvent = (payload, imageFile) => {
        setIsLoading(true);
        ApiServices.method.editTeachingMethod(payload)
            .then(async res => {
                if (imageFile) await ApiServices.method.uploadImage(res.profile_image_url_put, imageFile);
                return res;
            })
            .then(res => {
                Toast.showSuccess("Sửa tin tức thành công.");
                toggleEditButton();
                onEditSuccess && onEditSuccess(new TeachingMethodObject({...record, ...res}))
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    return (
        <Fragment>
            <Button type="primary" onClick={toggleEditButton}>Sửa</Button>
            <Modal visible={isEdit} footer={null} onCancel={toggleEditButton} width={1000} destroyOnClose>
                <TeachingMethodAddEditModal editPayload={record} onSave={onEditEvent} isLoading={isLoading}/>
            </Modal>
        </Fragment>
    )
}

export default function TeachingMethodPage() {
    const [teachingMethodList, setTeachingMethodList] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiServices.method.getTeachingMethodList()
            .then(setTeachingMethodList)
            .catch(console.error)
            .finally(() => setIsLoading(false));
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleAddTeachingMethod = () => {
        setIsShowModal(!isShowModal);
    }

    const onAddTeachingMethod = (payload, imageFile) => {
        setIsLoading(true);
        ApiServices.method.addTeachingMethod(payload)
            .then(res => {
                const _newMethod = new TeachingMethodObject({...payload, ...res});
                return imageFile
                    ? ApiServices.event.uploadImage(res.profile_image_url_put, imageFile).then(() => _newMethod)
                    : _newMethod;
            })
            .then(newMethod => {
                setTeachingMethodList([newMethod, ...teachingMethodList]);
                Toast.showSuccess("Tạo phương pháp giảng dạy thành công.");
                toggleAddTeachingMethod();
            })
            .catch(() => Toast.showError)
            .finally(() => setIsLoading(false));
    }

    const onEditItemSuccess = (payload) => {
        setTeachingMethodList(teachingMethodList.map(e => {
            return e.id === payload.id ? payload : e;
        }));
    }

    /**@param {TeachingMethodObject} teachingMethodObject*/
    const onClickDeleteItem = (teachingMethodObject) => {
        ApiServices.method.deleteTeachingMethod(teachingMethodObject.slug)
            .then(() => Toast.showSuccess("Xóa phương pháp giảng dạy thành công"))
            .then(() => setTeachingMethodList(teachingMethodList.filter(ev => ev.id !== teachingMethodObject.id)))
            .catch(() => Toast.showError());
    }

    const columns = [
        {
            title: 'Hình nền',
            dataIndex: 'profileImg',
            key: 'profileImg',
            render: (text, record) => <img src={record.imgUrl} alt="" width={50}/>
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Miêu tả',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => truncate(stripHtml(record.description))
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            key: 'content',
            render: (text, record) => truncate(stripHtml(record.content))
        },
        {
            title: 'Tác giả',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <TeachingMethodEditButton record={record} onEditSuccess={onEditItemSuccess}/>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Phương pháp giảng dạy"/>
            <div className="page__title">
                <h1>Phương pháp giảng dạy</h1>
                <Button
                    type="text"
                    className="button--add"
                    onClick={toggleAddTeachingMethod}
                >
                    Thêm phương pháp giảng dạy
                </Button>
            </div>
            <Table dataSource={teachingMethodList} columns={columns}/>
            <Modal visible={isShowModal} footer={null} onCancel={toggleAddTeachingMethod} width={1000} destroyOnClose>
                <TeachingMethodAddEditModal onSave={onAddTeachingMethod} isLoading={isLoading}/>
            </Modal>
        </BasePage>
    )
}

TeachingMethodEditButton.propTypes = {
    record: instanceOf(TeachingMethodObject),
    onEditSuccess: func
}