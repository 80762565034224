import React, {useEffect, useState} from 'react';
import BasePage from "../shared/basepage/BasePage";
import ApiServices from "../../services/apiservices";
import {Button, Popconfirm, Space, Table} from "antd";
import {LandingPageKeys} from "../../utils/constants";
import {LandingPageObject} from "../../models/LandingPageObject";
import {Toast} from "../../utils/toast";
import {LandingPagePreview} from "../shared/landingpages/LandingPagePreview";
import LandingPageAddEditModal from "../shared/landingpages/LandingPageAddEditModal";

export default function LearningPathsPage() {
    const [landingPages, setLandingPages] = useState([]);
    const [modalContent, setModalContent] = useState(null);

    useEffect(() => {
        ApiServices.landing.getLandingPages()
            .then(res => setLandingPages(res.filter(ld => ld.key === LandingPageKeys.LEARNING_PATHS)));
    }, []);

    const onModalClose = () => setModalContent(null);

    const onSubmit = async (payload) => {
        const isEdit = Boolean(payload.id);
        const promise = isEdit ? ApiServices.landing.editLandingPage(payload) : ApiServices.landing.addLandingPage(payload);

        return promise
            .then(res => {
                if (isEdit) {
                    setLandingPages(landingPages.map(ld => {
                        return ld.id === payload.id ? new LandingPageObject(payload) : ld;
                    }))
                } else {
                    setLandingPages([new LandingPageObject({...payload, ...res}), ...landingPages]);
                }
            })
            .then(() => {
                Toast.showSuccess((isEdit ? "Sửa" : "Thêm") + " lộ trình học tập thành công.");
                setModalContent(null);
            })
    }

    const toggleModal = (payload) => {
        if (payload && payload.id === modalContent?.id) {
            setModalContent(null);
        } else {
            setModalContent(<LandingPageAddEditModal type='lộ trình học tập' payload={payload} onClose={onModalClose}
                                                     onSubmit={onSubmit}/>)
        }
    }

    const onClickDeleteItem = (record) => {
        ApiServices.landing.deleteLandingPage(record.slug)
            .then(() => {
                Toast.showSuccess('Xóa lộ trình học tập thành công');
                setLandingPages(landingPages.filter(ld => ld.id !== record.id));
            })
            .catch(() => Toast.showError());
    }

    const columns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: "Landing page",
            key: 'edit',
            render: (text, record) => (
                <LandingPagePreview htmlContent={record.htmlContent}/>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <Button type='primary' onClick={() => toggleModal(record)}>
                        Sửa
                    </Button>
                    <Popconfirm
                        title="Bạn có chắc chắn xóa?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <BasePage>
            <div className="page__title">
                <h1>Lộ trình học tập</h1>
                <Button
                    type="text"
                    className="button--add"
                    onClick={() => toggleModal()}
                >
                    Thêm lộ trình học tập
                </Button>
            </div>
            <Table dataSource={landingPages} columns={columns} rowKey='id'/>
            {modalContent}
        </BasePage>
    )
}
