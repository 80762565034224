import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import {Toast} from "../../utils/toast";
import ExamAddEditModal from "./ExamAddEditModal";
import {truncate} from "lodash";
import {stripHtml} from "../../utils/helpers";
import ExamEditButton from "./ExamEditButton";
import {ExamObject} from "../../models/ExamObject";

export default function ExamPage() {
    const [examList, setExamList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiServices.exam.getExamList()
            .then(setExamList)
            .catch(console.error)
            .finally(() => setIsLoading(false))

        ApiServices.category.getCategoryList()
            .then(setCategoryList);
    }, []);

    const getCatLabel = (catId) => {
        return categoryList.find(cat => cat.id === catId)?.title;
    }

    const toggleAddExam = () => {
        setIsShowModal(!isShowModal);
    }

    const onAddExam = (payload, imageFile) => {
        setIsLoading(true);
        ApiServices.exam.addExam(payload)
            .then(res => {
                imageFile && ApiServices.exam.uploadImage(res.profile_image_url_put, imageFile);
                const newExam = new ExamObject({...payload, ...res});
                setExamList([newExam, ...examList]);
                Toast.showSuccess("Tạo bài kiểm tra thành công.");
                toggleAddExam();
            })
            .catch(() => Toast.showError)
            .finally(() => setIsLoading(false));
    }

    const onEditItemSuccess = (payload) => {
        setExamList(examList.map(ex => {
            return ex.id === payload.id ? payload : ex;
        }));
    }

    const onClickDeleteItem = (examObject) => {
        ApiServices.exam.deleteExam(examObject.slug)
            .then(() => Toast.showSuccess("Xóa bài kiểm tra thành công"))
            .then(() => setExamList(examList.filter(t => t.id !== examObject.id)))
            .catch(() => Toast.showError());
    }

    const columns = [
        {
            title: 'Hình nền',
            dataIndex: 'profileImg',
            key: 'profileImg',
            render: (text, record) => <img src={record.imgUrl} alt="" width={50}/>
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Miêu tả',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => truncate(stripHtml(record.description))
        },
        {
            title: 'Đường dẫn',
            dataIndex: 'formsUrl',
            key: 'formsUrl',
            render: (text, record) => truncate(record.formsUrl)
        },
        {
            title: 'Danh mục',
            dataIndex: 'category',
            key: 'category',
            render: (text, record) => <div>{getCatLabel(record.categoryId)}</div>
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <ExamEditButton record={record} onEditSuccess={onEditItemSuccess} categoryList={categoryList}/>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Kiểm tra trình độ"/>
            <div className="page__title">
                <h1>Kiểm tra trình độ</h1>
                <Button
                    type="text"
                    className="button--add"
                    onClick={toggleAddExam}
                >
                    Thêm bài kiểm tra
                </Button>
            </div>
            <Table dataSource={examList} columns={columns}/>
            <Modal visible={isShowModal} footer={null} onCancel={toggleAddExam} width={1000} destroyOnClose>
                <ExamAddEditModal onSave={onAddExam} isLoading={isLoading} categoryList={categoryList}/>
            </Modal>
        </BasePage>
    )
}