import React, {useState} from 'react';
import {Button, Form, Input, Modal, Upload} from "antd";
import {normFile} from "../../utils/helpers";
import {func} from "prop-types";

export function ImageGalleryUploadButton({onUpload}) {
    const [isOpen, setIsOpen] = useState(false);

    const onSubmit = (payload) => {
        onUpload(payload).then(() => setIsOpen(false));
    }

    const toggleModal = () => setIsOpen(!isOpen);

    return (
        <div>
            <Button onClick={toggleModal}>Upload</Button>
            <Modal visible={isOpen} footer={false} width={600} onCancel={toggleModal} destroyOnClose>
                <h2>Thêm hình ảnh lớp học</h2>
                <Form onFinish={onSubmit} labelCol={{span: 6}} wrapperCol={{span: 14}}>
                    <Form.Item
                        name="title"
                        label="Tiêu đề"
                        rules={[{required: true, message: "Bạn phải nhập tiêu đề"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name='imgData'
                               label='Hình ảnh lớp học'
                               valuePropName='fileList'
                               rules={[{required: true, message: "Bạn phải upload hình ảnh."}]}
                               getValueFromEvent={e => normFile(e, {}, 'imgData')}
                    >
                        <Upload
                            name="imgData"
                            listType="picture"
                            beforeUpload={() => false}
                            maxCount={1}
                        >
                            <Button icon={<i className="bi bi-upload mgr-10"/>}>Upload Hình ảnh</Button>
                        </Upload>
                    </Form.Item>
                    <Button type="primary" htmlType="submit">Lưu</Button>
                </Form>
            </Modal>
        </div>
    )
}

ImageGalleryUploadButton.propTypes = {
    onUpload: func
}
