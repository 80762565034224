import React, {useMemo} from "react";
import JoditEditor from "jodit-react";

const RichTextEditor = ({config, onChange, value}) => {
    const _config = {
        tabIndex: 0,
        uploader: {
            "insertImageAsBase64URI": true
        },
        ...config
    }

    return useMemo(() => (
        <JoditEditor value={value} config={_config} onChange={onChange}/>
        // eslint-disable-next-line
    ), [])
}

export default RichTextEditor
