import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import {Toast} from "../../utils/toast";
import {truncate} from "lodash";
import {ClassEditButton} from "./ClassEditButton";
import ClassAddEditModal from "./ClassAddEditModal";
import {ClassObject} from "../../models/ClassObject";
import {stripHtml} from "../../utils/helpers";

export default function ClassPage() {
    const [categoryList, setCategoryList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        Promise.all([
            ApiServices.category.getCategoryList(),
            ApiServices.class.getClassList()
        ])
            .then(([categoryList, classList]) => {
                setCategoryList(categoryList);
                setClassList(classList);
            })
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [])

    const toggleAddClass = () => {
        setShowAdd(!showAdd);
    }

    const onAddClass = (payload, imageFile) => {
        setIsLoading(true);
        ApiServices.class.addClass(payload)
            .then(res => {
                res.profile_image_url_put && ApiServices.class.uploadImage(res.profile_image_url_put, imageFile);

                const newClass = imageFile
                    ? new ClassObject({...payload, id: res.id, slug: res.slug})
                    : new ClassObject({...payload, id: res.id, slug: res.slug, imgUrl: payload.imgUrl})
                setClassList([newClass, ...classList]);
                Toast.showSuccess('Tạo chương trình học thành công.');
                toggleAddClass();
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    const onClickEditItem = (payload, imageFile) => {
        setIsLoading(true);
        return ApiServices.class.editClass(payload)
            .then(res => {
                return imageFile ? ApiServices.class.uploadImage(res.profile_image_url_put, imageFile) : null;
            })
            .then(() => {
                Toast.showSuccess("Sửa chương trình học thành công.");
                setClassList(classList.map(cl => {
                    return cl.id === payload.id ? payload : cl;
                }));
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    /**@param {ClassObject} classObject*/
    const onClickDeleteItem = (classObject) => {
        ApiServices.class.deleteClass(classObject.slug)
            .then(() => Toast.showSuccess("Xóa chương trình học thành công."))
            .then(() => setClassList(classList.filter(cat => cat.id !== classObject.id)))
            .catch(() => Toast.showError());
    }

    const columns = [
        {
            title: 'Hình nền',
            dataIndex: 'profileImg',
            key: 'profileImg',
            render: (text, record) => <img src={record.profileImageUrl} alt="" width={50}/>
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Danh mục',
            dataIndex: 'categoryId',
            key: 'categoryId',
            render: (text, record) => {
                const cat = categoryList.find(c => c.id === record.categoryId);
                return <div>{cat && cat.title}</div>
            }
        },
        {
            title: 'Miêu tả',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => truncate(stripHtml(record.description))
        },
        {
            title: 'Link bài test',
            dataIndex: 'formsUrl',
            key: 'formsUrl',
            render: (text, record) => truncate(record.formsUrl)
        },
        {
            title: "Thời lượng",
            dataIndex: "duration",
            key: "duration",
            render: (text, record) => record.duration + ' giờ'
        },
        {
            title: "Giảng viên",
            dataIndex: "teacher",
            key: "teacher",
        },
        {
            title: 'Số lượng bài học',
            key: 'lessonCount',
            render: (text, record) => record.lessonCount
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <ClassEditButton record={record} onSave={onClickEditItem} categoryList={categoryList}/>
                    <Popconfirm
                        title="Xóa chương trình học sẽ xóa tất cả bài học. Tiếp tục?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Chương trình học"/>
            <div>
                <div className="page__title">
                    <h1>Chương trình học</h1>
                    <Button
                        type="text"
                        className="button--add"
                        onClick={toggleAddClass}
                    >
                        Thêm chương trình học
                    </Button>
                </div>
                <Table dataSource={classList} columns={columns}/>
            </div>
            <Modal visible={showAdd} footer={null} onCancel={toggleAddClass} width={1000} destroyOnClose>
                <ClassAddEditModal onSave={onAddClass} isLoading={isLoading} categoryList={categoryList}/>
            </Modal>
        </BasePage>
    )
}
