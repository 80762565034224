import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {func, instanceOf, string} from "prop-types";
import {LandingPageObject} from "../../../models/LandingPageObject";
import HtmlContent from "../form/HtmlContent";
import {handleFileUpload} from "../../../utils/helpers";
import {LandingPageKeys} from "../../../utils/constants";
import {Toast} from "../../../utils/toast";

export default function LandingPageAddEditModal({type, payload, onSubmit, onClose}) {
    const [isLoading, setIsLoading] = useState(false);

    const getInitialValues = payload ? {title: payload.title} : {};

    const onSubmitHandler = async (item) => {
        setIsLoading(true);
        const htmlContent = item.htmlFile ? await handleFileUpload(item.htmlFile[0]?.originFileObj) : item.htmlContent;
        onSubmit({...item, htmlContent, key: LandingPageKeys.LEARNING_PATHS, id: payload?.id})
            .catch(() => {
                Toast.showError();
                setIsLoading(false);
            })
    }

    return (
        <Modal visible={true} footer={null} onCancel={onClose} destroyOnClose width={1000}>
            <h2>{(payload ? "Sửa " : "Thêm ") + type}</h2>
            <Form labelCol={{span: 6}}
                  wrapperCol={{span: 14}}
                  onFinish={onSubmitHandler}
                  initialValues={getInitialValues}
                  name='landing-form'
            >
                <Form.Item
                    name="title"
                    label="Tên lộ trình"
                    rules={[{required: true, message: "Bạn phải nhập tên lộ trình"}]}
                >
                    <Input/>
                </Form.Item>
                <HtmlContent/>
                <Button loading={isLoading} type="primary" htmlType="submit">Lưu</Button>
            </Form>
        </Modal>
    )
}

LandingPageAddEditModal.propTypes = {
    type: string,
    payload: instanceOf(LandingPageObject),
    onSubmit: func,
    onClose: func
}