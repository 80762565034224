export class BannerObject {
    constructor(data) {
        this.id = data.id;
        this.key = this.id;
        this.title = data.title;
        this.largeImgData = data.largeImgData;
        this.smallImgData = data.smallImgData;
        this.largeImgUrl = data.largeImgUrl || data.large_img_url_get || data.large_img || "";
        this.smallImgUrl = data.smallImgUrl || data.small_img_url_get || data.small_img || "";
        this.htmlContent = data.html_content || "Chưa có nội dung";
    }
}