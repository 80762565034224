import {ApiBase} from "./ApiBase";
import {RegistrationObject} from "../../models/RegistrationObject";
import {sortBy} from "lodash";

export class ApiRegistration extends ApiBase {
    /**@return {Promise<RegistrationObject[]>}*/
    getRegistrationList() {
        return this._generateRequest("/registration/list")
            .then(fetch)
            .then(this._handleResponse)
            .then(res => res.data.map(re => new RegistrationObject(re)))
            .then(res => sortBy(res, ['createdAt']).reverse());
    }
}
