import React, {useState} from 'react';
import {Button, Form, Modal} from "antd";
import {bool, func, instanceOf} from "prop-types";
import {LandingPageObject} from "../../../models/LandingPageObject";
import {handleFileUpload} from "../../../utils/helpers";
import ImageUploader from "../form/ImageUploader";
import HtmlContent from "../form/HtmlContent";

export default function LandingPageEditButton({record, addOrEditItem, isLoading, multipleImg = false}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => setIsOpen(!isOpen);

    const onSubmit = async (payload) => {
        const htmlContent = payload.htmlFile ? await handleFileUpload(payload.htmlFile[0]?.originFileObj) : payload.htmlContent;
        await addOrEditItem({...record, ...payload, htmlContent});
        toggleModal();
    }

    return (
        <>
            <Button onClick={toggleModal} type='primary'>Sửa</Button>
            <Modal visible={isOpen} onCancel={toggleModal} footer={null} destroyOnClose width='80vw'>
                <div>
                    <h2>Sửa nội dung {record.title}</h2>
                    <Form labelCol={{span: 6}}
                          wrapperCol={{span: 14}}
                          onFinish={onSubmit}
                          name='landing-form'
                    >
                        {!multipleImg && (
                            <ImageUploader
                                editPayload={record}
                                label='Hình ảnh'
                            />
                        )}
                        {multipleImg && (
                            <>
                                <ImageUploader
                                    editPayload={record}
                                    label='Hình ảnh (Mobile)'
                                    imgDataObject='imgDataSmall'
                                    imgAttr='smallImgUrl'
                                />
                                <ImageUploader
                                    editPayload={record}
                                    label='Hình ảnh (Desktop)'
                                    imgDataObject='imgDataLarge'
                                    imgAttr='largeImgUrl'
                                />
                            </>
                        )}

                        <HtmlContent/>
                        <Button loading={isLoading} type="primary" htmlType="submit">Lưu banner</Button>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

LandingPageEditButton.propTypes = {
    record: instanceOf(LandingPageObject),
    addOrEditItem: func,
    isLoading: bool,
    multipleImg: bool
}
