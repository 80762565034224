import LoginPage from "./components/loginpage/LoginPage";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import {useSelector} from "react-redux";
import {PathNames} from "./utils/pathNames";
import React, {useEffect, useState} from "react";
import NotFound from "./components/shared/notfound/NotFound";
import CategoryPage from "./components/categorypage/CategoryPage";
import TopicPage from "./components/lessonpage/LessonPage";
import JobPage from "./components/jobpage/JobPage";
import RegistrationPage from "./components/registrationpage/RegistrationPage";
import NewsPage from "./components/newspage/NewsPage";
import ClassPage from "./components/classpage/ClassPage";
import ExamPage from "./components/exampage/ExamPage";
import BannerPage from "./components/bannerpage/BannerPage";
import TeachingMethodPage from "./components/teachingmethodpage/TeachingMethodPage";
import HomePage from "./components/homepage/HomePage";
import ImageGalleryPage from "./components/imagegallerypage/ImageGalleryPage";
import CoursePage from "./components/coursepage/CoursePage";
import LearningPathsPage from "./components/learningpathspage/LearningPathsPage";

export default function App() {
    const [isAuth, setIsAuth] = useState(false);
    /**@type {UserCredentials}*/
    const userObject = useSelector(state => {
        return state.user;
    })

    const isLoginPage = () => window.location.pathname === PathNames.Login

    useEffect(() => {
        if (isLoginPage()) return;
        if (!(userObject && userObject.expirationTime > new Date().getTime())) {
            window.location.replace(PathNames.Login);
        } else {
            setIsAuth(true);
        }
    }, [userObject]);

    return (
        <BrowserRouter>
            <div className="App">
                {!isAuth && !isLoginPage() ? <div/> :
                    <Switch>
                        <Route exact path={PathNames.HomePage}>
                            <Dashboard/>
                        </Route>
                        <Route exact path={PathNames.FEHome}>
                            <HomePage/>
                        </Route>
                        <Route exact path={PathNames.Banner}>
                            <BannerPage/>
                        </Route>
                        <Route exact path={PathNames.Login}>
                            <LoginPage/>
                        </Route>
                        <Route exact path={PathNames.LearningPaths}>
                            <LearningPathsPage/>
                        </Route>
                        <Route exact path={PathNames.Category}>
                            <CategoryPage/>
                        </Route>
                        <Route exact path={PathNames.Courses}>
                            <CoursePage/>
                        </Route>
                        <Route exact path={PathNames.Class}>
                            <ClassPage/>
                        </Route>
                        <Route path={PathNames.Lesson}>
                            <TopicPage/>
                        </Route>
                        <Route exact path={PathNames.Exams}>
                            <ExamPage/>
                        </Route>
                        <Route exact path={PathNames.Jobs}>
                            <JobPage/>
                        </Route>
                        <Route exact path={PathNames.Registrations}>
                            <RegistrationPage/>
                        </Route>
                        <Route exact path={PathNames.News}>
                            <NewsPage/>
                        </Route>
                        <Route exact path={PathNames.TeachingMethods}>
                            <TeachingMethodPage/>
                        </Route>
                        <Route exact path={PathNames.ImageGallery}>
                            <ImageGalleryPage/>
                        </Route>
                        <Route path="*">
                            <NotFound/>
                        </Route>
                    </Switch>
                }
            </div>
        </BrowserRouter>
    );
}
