import React from 'react';
import {normFile} from "../../../utils/helpers";
import {Button, Form, Upload} from "antd";
import {bool} from "prop-types";

export default function HtmlContent({isRequired, ...props}) {
    return (
        <Form.Item
            {...props}
            label='HTML file'
            name='htmlFile'
            rules={isRequired ? [{required: true, message: "Bạn phải upload file"}] : []}
            valuePropName='fileList'
            getValueFromEvent={e => normFile(e, {}, 'htmlFile')}
        >
            <Upload
                name='htmlFile'
                type='file'
                accept='text/html'
                beforeUpload={() => false}
            >
                <Button icon={<i className="bi bi-upload mgr-10"/>}>Upload file</Button>
            </Upload>
        </Form.Item>
    )
}

HtmlContent.propTypes = {
    isRequired: bool
}