import React, {useState} from 'react';
import {Button, Form, Input, Upload} from "antd";
import {getDefaultImageList, normFile} from "../../utils/helpers";
import {bool, func, instanceOf} from "prop-types";
import {BannerObject} from "../../models/BannerObject";

/**@param {BannerObject} editPayload
 * @param {Function} onSave
 * @param {Boolean} isLoading
 */
export default function BannerAddEditModal({editPayload, onSave, isLoading}) {
    const [htmlContent, setHtmlContent] = useState();

    const onSubmit = (payload) => {
        onSave && onSave({...editPayload, ...payload, htmlContent});
    }

    const handleFileUpload = (event) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            setHtmlContent(event.target.result);
        }

        reader.readAsText(event.target.files[0]);
    }

    return (
        <div>
            <h1>{editPayload ? "Sửa banner" : "Thêm banner"}</h1>
            <div>
                <Form labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      onFinish={onSubmit}
                      name="event-form"
                      initialValues={editPayload || {}}
                >
                    <Form.Item
                        name="title"
                        label="Tên banner"
                        rules={[{required: true, message: "Bạn phải nhập tên banner"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="largeImgData"
                        label="Hình nền lớn (1440x500 px)"
                        valuePropName="fileList"
                        getValueFromEvent={e => normFile(e, editPayload, 'largeImgUrl')}
                    >
                        <Upload
                            name="largeImgData"
                            listType="picture"
                            beforeUpload={() => false}
                            maxCount={1}
                            defaultFileList={getDefaultImageList(editPayload, 'largeImgUrl')}
                        >
                            <Button icon={<i className="bi bi-upload mgr-10"/>}>Upload Hình lớn</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="smallImgData"
                        label="Hình nền nhỏ (450x350 px)"
                        valuePropName="fileList"
                        getValueFromEvent={e => normFile(e, editPayload, 'smallImgUrl')}
                    >
                        <Upload
                            name="smallImgData"
                            listType="picture"
                            beforeUpload={() => false}
                            defaultFileList={getDefaultImageList(editPayload, 'smallImgUrl')}
                        >
                            <Button icon={<i className="bi bi-upload mgr-10"/>}>Upload Hình nhỏ</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name="htmlFile" label="Nội dung landing page">
                        <input type='file' accept='text/html' onChange={handleFileUpload}/>
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Lưu banner</Button>
                </Form>
            </div>
        </div>
    )
}

BannerAddEditModal.propTypes = {
    editPayload: instanceOf(BannerObject),
    onSave: func,
    isLoading: bool
}