import {ApiBase} from "./ApiBase";
import {BannerObject} from "../../models/BannerObject";

export class ApiBanner extends ApiBase {
    /**@return {Promise<BannerObject[]>}*/
    getBannerList() {
        return this._generateRequest("/banner/list")
            .then(fetch)
            .then(this._handleResponse)
            .then(res => res.data.map(banner => new BannerObject(banner)))
    }

    /**@param {BannerObject} payload */
    _generatePayload(payload) {
        const _payload = {
            title: payload.title,
            html_content: payload.htmlContent
        }

        if (payload["id"]) {
            _payload['id'] = payload.id;
        }

        payload.largeImgData && (_payload['large_img'] = payload.largeImgData[0].name);
        payload.smallImgData && (_payload['small_img'] = payload.smallImgData[0].name);

        return _payload;
    }

    /**@param {BannerObject} payload
     * @return {Promise}*/
    addBanner(payload) {
        return this._requester.post("/banner", this._generatePayload(payload))
            .then(res => res.data.data);
    }

    /**@param {BannerObject} payload*/
    editBanner(payload) {
        return this._requester.put("/banner", this._generatePayload(payload))
            .then(res => res.data.data);
    }

    deleteBanner(bannerId) {
        return this._requester.delete("/banner/" + bannerId);
    }
}
