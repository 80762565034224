import {Button, Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useState} from "react";
import RichTextEditor from "../shared/form/RichTextEditor";
import {bool, func, instanceOf} from "prop-types";
import {TeachingMethodObject} from "../../models/TeachingMethodObject";

/**@param {TeachingMethodObject} editPayload
 * @param {Function} onSave
 * @param {Boolean} isLoading
 */
export default function TeachingMethodAddEditModal({editPayload, onSave, isLoading}) {
    const [imgName, setImgName] = useState("");
    const [imgFile, setImgFile] = useState(null);

    const onSubmit = (payload) => {
        onSave && onSave({...editPayload, ...payload, imgName}, imgFile);
    }

    const getInitialValues = () => {
        if (editPayload) {
            return {
                title: editPayload.title,
                description: editPayload.description,
                author: editPayload.author,
                content: editPayload.content
            }
        } else {
            return {};
        }
    }

    const getFileName = (event) => {
        //eslint-disable-next-line
        setImgName(event.target.value.replace(/^.*[\\\/]/, ''));
        setImgFile(event.target.files[0]);
    }

    return (
        <div>
            <h1>{editPayload ? "Sửa phương pháp giảng dạy" : "Thêm phương pháp giảng dạy"}</h1>
            <Form labelCol={{span: 6}}
                  wrapperCol={{span: 14}}
                  onFinish={onSubmit}
                  name="event-form"
                  initialValues={getInitialValues()}
            >
                <Form.Item
                    name="title"
                    label="Tiêu đề phương pháp giảng dạy"
                    rules={[{required: true, message: "Bạn phải nhập tiêu đề phương pháp giảng dạy"}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item name="description" label="Miêu tả ngắn">
                    <TextArea/>
                </Form.Item>
                <Form.Item
                    name="content"
                    label="Nội dung"
                    rules={[{required: true, message: "Bạn phải nhập nội dung"}]}
                >
                    <RichTextEditor config={{
                        minHeight: 400,
                        tabIndex: 0,
                        uploader: {
                            "insertImageAsBase64URI": true
                        }
                    }}/>
                </Form.Item>
                <Form.Item name="author" label="Tác giả">
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="imgUrl"
                    label="Hình nền"
                >
                    <Input type="file" accept="image/*" onChange={getFileName}/>
                </Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit">Lưu phương pháp giảng dạy</Button>
            </Form>
        </div>
    )
}

TeachingMethodAddEditModal.propTypes = {
    editPayload: instanceOf(TeachingMethodObject),
    onSave: func,
    isLoading: bool
}