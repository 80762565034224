import styles from "./JobPage.module.css";
import BasePage from "../shared/basepage/BasePage";
import React, {Fragment, useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import PageTitle from "../shared/PageTitle";
import JobAddEditModal from "./JobAddEditModal";
import {Toast} from "../../utils/toast";
import {JobObject} from "../../models/Job";
import {truncate} from "lodash";
import {stripHtml} from "../../utils/helpers";
import {func, instanceOf} from "prop-types";

function JobEditButton({record, onEditSuccess}) {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleEditButton = () => {
        setIsEdit(!isEdit);
    }

    const onEditJob = (payload, imageFile) => {
        setIsLoading(true);
        ApiServices.job.editJob(payload)
            .then(res => {
                setIsLoading(false);
                imageFile && ApiServices.job.uploadImage(res.profile_image_url_put, imageFile);
                Toast.showSuccess("Sửa danh mục thành công.");
                setTimeout(() => onEditSuccess(new JobObject(payload)), 200);
                toggleEditButton();
            })
            .catch(() => {
                Toast.showError();
                setIsLoading(false);
            })
    }

    return (
        <Fragment>
            <Button type="primary" onClick={toggleEditButton}>Sửa</Button>
            <Modal visible={isEdit} footer={null} onCancel={toggleEditButton} width={1000} destroyOnClose>
                <JobAddEditModal editPayload={record} onSave={onEditJob} isLoading={isLoading}/>
            </Modal>
        </Fragment>
    )
}

export default function JobPage() {
    const [jobList, setJobList] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiServices.job.getJobList()
            .then(setJobList)
            .catch(console.error)
            .finally(() => setIsLoading(false));
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**@param {JobObject} payload
     * @param {File} imageFile*/
    const onAddJob = (payload, imageFile) => {
        ApiServices.job.addJob(payload)
            .then(res => {
                imageFile && ApiServices.job.uploadImage(res.profile_image_url_put, imageFile);
                setJobList([new JobObject({
                    ...payload,
                    id: res.id,
                    slug: res.slug
                }), ...jobList]);
                Toast.showSuccess('Tạo việc làm thành công.');
                toggleModal();
            })
            .catch(() => {
                Toast.showError();
                setIsLoading(false);
            })
    }

    /**@param {JobObject} payload*/
    const onEditSuccess = (payload) => {
        setJobList(jobList.map(j => {
            return j.id === payload.id ? payload : j;
        }))
    }

    /**@param {JobObject} jobObject*/
    const onClickDeleteItem = (jobObject) => {
        ApiServices.job.deleteJob(jobObject.slug)
            .then(() => Toast.showSuccess("Xóa danh mục thành công."))
            .then(() => setJobList(jobList.filter(j => j.id !== jobObject.id)))
            .catch(() => Toast.showError());
    }

    const toggleModal = () => {
        setIsShowModal(!isShowModal);
    }

    const columns = [
        {
            title: 'Hình nền',
            dataIndex: 'profileImg',
            key: 'profileImg',
            render: (text, record) => <img src={record.imgUrl} alt="" width={50}/>
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Miêu tả',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => truncate(stripHtml(record.description))
        },
        {
            title: 'Vị trí',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Nơi làm việc',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Form đăng ký',
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => truncate(record.url)
        },
        {
            title: 'Trạng thái',
            dataIndex: 'isOpen',
            key: 'isOpen',
            render: (text, record) => (
                <div className={record.isOpen ? styles.open : styles.closed}>
                    {record.isOpen ? "Đang mở" : "Đóng"}
                </div>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <JobEditButton record={record} onEditSuccess={onEditSuccess}/>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Việc làm"/>
            <div>
                <div className="page__title">
                    <h1>Việc làm</h1>
                    <Button
                        type="text"
                        className="button--add"
                        onClick={toggleModal}
                    >
                        Thêm việc làm
                    </Button>
                </div>
                <Table dataSource={jobList} columns={columns}/>
            </div>
            <Modal visible={isShowModal} footer={null} onCancel={toggleModal} width={1000} destroyOnClose>
                <JobAddEditModal onSave={onAddJob} isLoading={isLoading}/>
            </Modal>
        </BasePage>
    )
}

JobEditButton.propTypes = {
    record: instanceOf(JobObject),
    onEditSuccess: func
}