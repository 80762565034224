import {createStore} from 'redux';
import appReducer from "./reducer";

let preloadedState
const persistedTodosString = localStorage.getItem('user_creds');

if (persistedTodosString) {
    preloadedState = {
        user: JSON.parse(persistedTodosString)
    }
}

export const store = createStore(appReducer, preloadedState);
