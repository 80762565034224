import moment from "moment";

export class RegistrationObject {
    constructor(data) {
        this.id = data.id;
        this.key = this.id;
        this.emailAddress = data.emailAddress || data.email || "";
        this.fullName = data.fullName || data.full_name || "";
        this.phoneNumber = data.phoneNumber || data.phone_number || "";
        this.level = data.level || "";
        this.createdAt = data.createdAt ? moment(data.createdAt) : moment(data.created_at);
    }
}