import BasePage from "../shared/basepage/BasePage";
import PageTitle from "../shared/PageTitle";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import {Toast} from "../../utils/toast";
import {truncate} from "lodash";
import {CourseEditButton} from "./CourseEditButton";
import CourseAddEditModal from "./CourseAddEditModal";
import {CourseObject} from "../../models/CourseObject";
import {stripHtml} from "../../utils/helpers";
import {CategoryFilters} from "../../utils/constants";
import {LandingPagePreview} from "../shared/landingpages/LandingPagePreview";

export default function CoursePage() {
    const [categoryList, setCategoryList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        Promise.all([
            ApiServices.category.getCategoryList(),
            ApiServices.course.getCourseList()
        ])
            .then(([categoryList, courseList]) => {
                setCategoryList(categoryList.filter(cat => cat.limit?.includes(CategoryFilters.COURSES) || !(cat.limit && cat.limit.length)));
                setCourseList(courseList);
            })
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [])

    const toggleAddClass = () => {
        setShowAdd(!showAdd);
    }

    const onAddClass = (payload, imageFile) => {
        setIsLoading(true);
        ApiServices.course.addCourse(payload)
            .then(async res => {
                imageFile && await ApiServices.class.uploadImage(res.profile_image_url_put, imageFile);
                setCourseList([new CourseObject({...payload, ...res}), ...courseList]);
                Toast.showSuccess('Tạo chương trình học thành công.');
                toggleAddClass();
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    const onClickEditItem = (payload, imageFile) => {
        setIsLoading(true);
        return ApiServices.course.editCourse(payload)
            .then(async res => {
                imageFile ? await ApiServices.course.uploadImage(res.profile_image_url_put, imageFile) : null;
                return res;
            })
            .then(res => {
                Toast.showSuccess("Sửa chương trình học thành công.");
                setCourseList(courseList.map(cl => {
                    return cl.id === payload.id ? new CourseObject({...payload, ...res}) : cl;
                }));
            })
            .catch(() => Toast.showError())
            .finally(() => setIsLoading(false));
    }

    /**@param {CourseObject} courseObject*/
    const onClickDeleteItem = (courseObject) => {
        ApiServices.course.deleteCourse(courseObject.slug)
            .then(() => Toast.showSuccess("Xóa chương trình học thành công."))
            .then(() => setCourseList(courseList.filter(cat => cat.id !== courseObject.id)))
            .catch(() => Toast.showError());
    }

    const columns = [
        {
            title: 'Hình nền',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            render: (text, record) => <img src={record.imgUrl} alt="" width={50}/>
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Danh mục',
            dataIndex: 'categoryId',
            key: 'categoryId',
            render: (text, record) => {
                const cat = categoryList.find(c => c.id === record.categoryId);
                return <div>{cat && cat.title}</div>
            }
        },
        {
            title: 'Miêu tả',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => truncate(stripHtml(record.description))
        },
        {
            title: "Xem trước",
            key: 'preview',
            render: (text, record) => (
                <LandingPagePreview htmlContent={record.htmlContent}/>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="small">
                    <CourseEditButton record={record} onSave={onClickEditItem} categoryList={categoryList}/>
                    <Popconfirm
                        title="Bạn có chắc chắn xoá?"
                        onConfirm={() => onClickDeleteItem(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type="primary">Xoá</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <BasePage isLoading={isLoading}>
            <PageTitle pageName="Khóa học"/>
            <div>
                <div className="page__title">
                    <h1>Khóa học</h1>
                    <Button
                        type="text"
                        className="button--add"
                        onClick={toggleAddClass}
                    >
                        Thêm khóa học
                    </Button>
                </div>
                <Table dataSource={courseList} columns={columns}/>
            </div>
            <Modal visible={showAdd} footer={null} onCancel={toggleAddClass} width={1000} destroyOnClose>
                <CourseAddEditModal onSave={onAddClass} isLoading={isLoading} categoryList={categoryList}/>
            </Modal>
        </BasePage>
    )
}
