import React, {useState} from "react";
import {Button, Modal} from "antd";
import {string} from "prop-types";

export const LandingPagePreview = ({buttonLabel = "Xem trước", htmlContent}) => {
    const [isShowModal, setIsShowModal] = useState(false);

    const toggleModal = () => setIsShowModal(!isShowModal);

    return (
        <>
            <Button onClick={toggleModal}>
                {buttonLabel}
            </Button>
            <Modal visible={isShowModal} footer={null} onCancel={toggleModal} width='100%' destroyOnClose>
                <div style={{marginTop: '30px'}} dangerouslySetInnerHTML={{__html: htmlContent}}/>
            </Modal>
        </>
    )
}

LandingPagePreview.propTypes = {
    buttonLabel: string,
    htmlContent: string
}