import {message} from "antd";

export const Toast = {
    showSuccess: (msg) => {
        const hide = message.success(msg, 0);
        setTimeout(hide, 2500);
    },
    showError: (msg = 'Đã xảy ra lỗi. Vui lòng thử lại sau.') => {
        const hide = message.error(msg, 0);
        setTimeout(hide, 2500)
    }
}

