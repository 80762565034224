import React from 'react';
import {Button, Form, Upload} from "antd";
import {getDefaultImageList, normFile} from "../../../utils/helpers";
import {bool, object, string} from "prop-types";

export default function ImageUploader({
                                          editPayload = {},
                                          isRequired,
                                          imgDataObject = 'imgData',
                                          imgAttr = 'imgUrl',
                                          label = 'Hình ảnh',
                                          ...props
                                      }) {
    return (
        <Form.Item
            {...props}
            label={label}
            name={imgDataObject}
            valuePropName='fileList'
            getValueFromEvent={e => normFile(e, {}, imgDataObject)}
            rules={isRequired ? [{required: true, message: "Bạn phải upload hình ảnh"}] : []}
        >
            <Upload name={imgDataObject}
                    accept='image/*'
                    listType="picture"
                    beforeUpload={() => false}
                    maxCount={1}
                    defaultFileList={getDefaultImageList(editPayload, imgAttr)}
            >
                <Button icon={<i className="bi bi-upload mgr-10"/>}>Upload hình ảnh</Button>
            </Upload>
        </Form.Item>
    )
}

ImageUploader.propTypes = {
    editPayload: object,
    isRequired: bool,
    imgDataObject: string,
    imgAttr: string,
    label: string
}
