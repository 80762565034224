import React, {useEffect, useState} from 'react';
import BasePage from "../shared/basepage/BasePage";
import ApiServices from "../../services/apiservices";
import styles from "./ImageGalleryPage.module.scss";
import PageTitle from "../shared/PageTitle";
import {ImageGalleryUploadButton} from "./ImageGalleryUploadButton";
import {Button, Popconfirm} from "antd";
import {Toast} from "../../utils/toast";
import {ImageGalleryObject} from "../../models/ImageGalleryObject";

export default function ImageGalleryPage() {
    const [imageGalleryList, setImageGalleryList] = useState([]);

    useEffect(() => {
        ApiServices.imageGallery.getImageGalleryList()
            .then(setImageGalleryList);
    }, [])

    const onAddNewImage = (payload) => {
        return ApiServices.imageGallery.addImage(payload)
            .then(async res => {
                await ApiServices.imageGallery.uploadImage(res.profile_image_url_put, payload.imgData[0].originFileObj);
                return res;
            })
            .then(imgObj => {
                setImageGalleryList([...imageGalleryList, new ImageGalleryObject({...payload, ...imgObj})])
                Toast.showSuccess("Thêm hình ảnh thành công.")
            })
            .catch(() => Toast.showError());
    }

    const onDeleteImage = (payload) => {
        return ApiServices.imageGallery.deleteImage(payload.slug)
            .then(() => {
                setImageGalleryList(imageGalleryList.filter(img => img.slug !== payload.slug));
                Toast.showSuccess("Xoá hình ảnh thành công.")
            })
            .catch(() => Toast.showError());
    }

    return (
        <BasePage>
            <PageTitle pageName="Hình ảnh lớp học"/>
            <div className={styles.header}>
                <h1>Hình ảnh lớp học</h1>
                <ImageGalleryUploadButton onUpload={onAddNewImage}/>
            </div>
            <div className={styles.imageGrid}>
                {imageGalleryList.map(img => (
                    <div key={img.id} className={styles.imgWrapper}>
                        <img src={img.profileImageUrl} alt={img.title}/>
                        <Popconfirm
                            title="Bạn có chắc chắn xóa?"
                            onConfirm={() => onDeleteImage(img)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger type="primary" size='small'>Xoá</Button>
                        </Popconfirm>
                    </div>
                ))}
            </div>
        </BasePage>
    )
}
