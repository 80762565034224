import React, {Fragment, useState} from "react";
import {Button, Modal} from "antd";
import BannerAddEditModal from "./BannerAddEditModal";
import {func, instanceOf} from "prop-types";
import {BannerObject} from "../../models/BannerObject";

/**@param {BannerObject} record
 * @param {Function} onSave*/
export function BannerEditButton({record, onSave}) {
    const [isEdit, setIsEdit] = useState(false);

    const toggleEditButton = () => {
        setIsEdit(!isEdit);
    }

    const saveHandler = (...params) => {
        onSave(...params).then(() => setIsEdit(false));
    }

    return (
        <Fragment>
            <Button type="primary" onClick={toggleEditButton}>Sửa</Button>
            <Modal visible={isEdit} footer={null} onCancel={toggleEditButton} width={1000} destroyOnClose>
                <BannerAddEditModal editPayload={record} onSave={saveHandler}/>
            </Modal>
        </Fragment>
    )
}

BannerEditButton.propTypes = {
    record: instanceOf(BannerObject),
    onSave: func,
}