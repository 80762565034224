import FbServices from "../../services/firebaseservice";
import {useDispatch} from 'react-redux'
import {INITIALIZE_USER_CRED} from "../../store/const";
import styles from "./LoginPage.module.css";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Input} from "antd";
import {PathNames} from "../../utils/pathNames";
import PageTitle from "../shared/PageTitle";
import {Toast} from "../../utils/toast";

export default function LoginPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    localStorage.removeItem("user_creds");

    const onInputUserNameHandler = (event) => {
        setUserName(event.target.value);
    }

    const onInputPasswordHandler = (event) => {
        setPassword(event.target.value);
    }

    const onClickLoginHandler = () => {
        setIsLoading(true);
        FbServices.auth.login(userName, password)
            .then(cred => {
                dispatch({type: INITIALIZE_USER_CRED, payload: cred});
                localStorage.setItem("user_creds", JSON.stringify(cred));
                history.push(PathNames.HomePage);
            })
            .catch(() => {
                Toast.showError("Đăng nhập không thành công.");
                setIsLoading(false);
            })
    }


    useEffect(() => {
        const enterKeyHandler = (e) => e.key === "Enter" && onClickLoginHandler();
        window.addEventListener("keydown", enterKeyHandler);
        return () => window.removeEventListener("keydown", enterKeyHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.wrapper}>
            <PageTitle pageName="Đăng nhập"/>
            <div className={styles.boxWrapper}>
                <div className={styles.logoSection}>
                    <img alt="logo" src="/logo.svg" width={80} height={80}/>
                    <h1>CoachingEnglish CMS</h1>
                </div>
                <div className={styles.mainSection}>
                    <Input
                        className={styles.inputBox}
                        addonBefore={<i className="bi bi-person-fill"/>}
                        placeholder="Email đăng nhập"
                        value={userName}
                        onChange={onInputUserNameHandler}
                    />
                    <Input.Password
                        className={styles.inputBox}
                        addonBefore={<i className="bi bi-lock-fill"/>}
                        placeholder="Mật khẩu"
                        value={password}
                        onChange={onInputPasswordHandler}
                    />
                    <div className={styles.btnWrapper}>
                        <Button
                            type="primary"
                            className={styles.loginBtn}
                            onClick={onClickLoginHandler}
                            disabled={!(userName && password)}
                        >
                            {isLoading
                                ? <i className={"bi bi-arrow-repeat " + styles.spinner}/>
                                : "Đăng nhập"
                            }
                        </Button>
                        <Button type="text" disabled>Quên mật khẩu</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
