import {ApiBase, HttpMethods} from "./ApiBase";
import {EventObject} from "../../models/Event";

export class ApiEvent extends ApiBase {
    /**@return {Promise<EventObject[]>}*/
    getEventList() {
        return this._generateRequest("/events")
            .then(fetch)
            .then(this._handleResponse)
            .then(res => res.map(e => new EventObject(e)));
    }

    _generatePayload(payload) {
        const _payload = {
            title: payload.title,
            start: this._formatDate(payload.startDate),
            end: this._formatDate(payload.endDate),
            description: payload.description,
            content: payload.content,
            url: payload.url
        }

        if (payload.imgData) {
            _payload['profile_image'] = payload.imgData[0].name;
        }

        if (payload.id) {
            _payload['id'] = payload.id;
        }

        return _payload;
    }

    /**@param {EventObject} payload*/
    addEvent(payload) {
        return this._requester.post("/event", this._generatePayload(payload))
            .then(res => res.data.data)
            .catch(console.error);
    }

    /**@param {EventObject} payload*/
    editEvent(payload) {
        return this._requester.put("/event", this._generatePayload(payload))
            .then(res => res.data.data);
    }

    /**@param {String} slug*/
    deleteEvent(slug) {
        return this._generateRequest("/event/" + slug, HttpMethods.DELETE)
            .then(fetch)
            .then(this._handleNonJSONResponse);
    }
}