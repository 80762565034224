import {Button, Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useEffect, useState} from "react";
import ApiServices from "../../services/apiservices";
import RichTextEditor from "../shared/form/RichTextEditor";
import {bool, func, instanceOf} from "prop-types";
import {NewsObject} from "../../models/NewsObject";

function useContent(slug) {
    const [isLoadingContent, setIsLoadingContent] = useState(true);
    const [initialContent, setInitialContent] = useState("");

    useEffect(() => {
        if (slug) {
            ApiServices.news.getNewsDetail(slug)
                .then(res => setInitialContent(res.content))
                .catch(console.error)
                .finally(() => setIsLoadingContent(false));
        } else {
            setIsLoadingContent(false);
        }
    }, [slug])

    return [initialContent, isLoadingContent];
}

/**@param {NewsObject} editPayload
 * @param {Function} onSave
 * @param {Boolean} isLoading
 */
export default function NewsAddEditModal({editPayload, onSave, isLoading}) {
    const [imgName, setImgName] = useState("");
    const [imgFile, setImgFile] = useState(null);

    const [initialContent, isLoadingContent] = useContent(editPayload ? editPayload.slug : "");

    const onSubmit = (payload) => {
        onSave && onSave({...editPayload, ...payload, imgName}, imgFile);
    }

    const getInitialValues = () => {
        if (editPayload) {
            return {
                title: editPayload.title,
                description: editPayload.description,
                author: editPayload.author,
                content: initialContent
            }
        } else {
            return {};
        }
    }

    const getFileName = (event) => {
        //eslint-disable-next-line
        setImgName(event.target.value.replace(/^.*[\\\/]/, ''));
        setImgFile(event.target.files[0]);
    }

    return (
        <div>
            <h1>{editPayload ? "Sửa tin tức" : "Thêm tin tức"}</h1>
            {!isLoadingContent && <div>
                <Form labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      onFinish={onSubmit}
                      name="event-form"
                      initialValues={getInitialValues()}
                >
                    <Form.Item
                        name="title"
                        label="Tiêu đề tin tức"
                        rules={[{required: true, message: "Bạn phải nhập tiêu đề tin tức"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name="description" label="Miêu tả ngắn">
                        <TextArea/>
                    </Form.Item>
                    <Form.Item
                        name="content"
                        label="Nội dung"
                        rules={[{required: true, message: "Bạn phải nhập nội dung"}]}
                    >
                        <RichTextEditor config={{
                            minHeight: 400,
                            tabIndex: 0,
                            uploader: {
                                "insertImageAsBase64URI": true
                            }
                        }}/>
                    </Form.Item>
                    <Form.Item name="author" label="Tác giả">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="imgUrl"
                        label="Hình nền"
                    >
                        <Input type="file" accept="image/*" onChange={getFileName}/>
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Lưu tin tức</Button>
                </Form>
            </div>}
        </div>
    )
}

NewsAddEditModal.propTypes = {
    editPayload: instanceOf(NewsObject),
    onSave: func,
    isLoading: bool
}