import {ApiBase, HttpMethods} from "./ApiBase";
import {LessonObject} from "../../models/LessonObject";

export class ApiLesson extends ApiBase {
    _generatePayLoad(payload) {
        const _payload = {
            class_id: payload.classId,
            title: payload.title,
            level: payload.level,
            type: payload.type,
            video: payload.video,
            text: payload.text,
            category_id: payload.categoryId
        }

        if (payload.pdfName) {
            _payload['pdf'] = payload.pdfName;
        }

        if (payload.id) {
            _payload["id"] = payload.id;
        }

        if (payload.slug) {
            _payload["slug"] = payload.slug;
        }

        return _payload;
    }

    /**@param {String} classId
     * @return {Promise<LessonObject[]>}*/
    getLessonOfClass(classId) {
        return this._generateGetRequestWQuery("/lesson/list", {class_id: classId})
            .then(fetch)
            .then(this._handleResponse)
            .then(res => res.data.map(ls => new LessonObject(ls)));
    }

    /**@param {LessonObject} payload */
    addLesson(payload) {
        return this._generateRequest('/lesson', HttpMethods.POST, this._generatePayLoad(payload))
            .then(fetch)
            .then(this._handleResponse);
    }

    /**@param {LessonObject} payload */
    editLesson(payload) {
        return this._generateRequest("/lesson", HttpMethods.PUT, this._generatePayLoad(payload))
            .then(fetch)
            .then(this._handleResponse);
    }

    deleteLesson(lessonSlug) {
        return this._generateRequest("/lesson/" + lessonSlug, HttpMethods.DELETE)
            .then(fetch)
            .then(this._handleNonJSONResponse);
    }
}
