import React, {Fragment, useState} from "react";
import {Button, Modal} from "antd";
import CategoryAddEditModal from "./CategoryAddEditModal";
import {func, instanceOf} from "prop-types";
import {CategoryObject} from "../../models/Category";

/**@param {CategoryObject} record
 * @param {Function} onSave*/
export function CategoryEditButton({record, onSave}) {
    const [isEdit, setIsEdit] = useState(false);

    const toggleEditButton = () => {
        setIsEdit(!isEdit);
    }

    const saveHandler = (...params) => {
        onSave(...params).then(() => setIsEdit(false));
    }

    return (
        <Fragment>
            <Button type="primary" onClick={toggleEditButton}>Sửa</Button>
            <Modal visible={isEdit} footer={null} onCancel={toggleEditButton} width={1000} destroyOnClose>
                <CategoryAddEditModal editPayload={record} onSave={saveHandler}/>
            </Modal>
        </Fragment>
    )
}

CategoryEditButton.propTypes = {
    record: instanceOf(CategoryObject),
    onSave: func
}
